<div class="content-container">
  <div class="login-container" [ngClass]="(!showLogin)?'blur-out':'blur-in'" *ngIf="showLogin; else showProccessing">
    <mat-card class="floating-login-card" #logincard>
      <div class="circle-placeholder">
        <div class="logo-circle">
          <img src="assets/images/procurant_logo.png" alt="Procurant" />
        </div>
      </div>
      <div class="login-header">
        <!-- <h1 *ngIf="!showEmailConformPage"> -->
          <h1 *ngIf="pageToShow == 'EMAIL_PAGE'">
          <!-- {{ 'COMPANY.LABEL.FORGOT_PASSWORD' | translate }} -->Forgot Password</h1>
        <p *ngIf="pageToShow == 'EMAIL_PAGE'">
          <!-- {{ 'COMPANY.LABEL.ENTER_REGISTER_EMAIL' | translate }} -->Please enter your registered email address with Procurant.</p>
      </div>
      <form [formGroup]="user" (ngSubmit)="checkUser()" *ngIf="pageToShow == 'EMAIL_PAGE'">
        <mat-form-field appearance="outline">
          <input matInput formControlName="userName" placeholder="Enter a valid email/username.">
          <mat-error *ngIf="user.get('userName').touched && user.get('userName').hasError('required')">
            {{ 'COMPANY.LABEL.EMAIL_USERNAME_REQUIRED' | translate }}
          </mat-error>
          <mat-error *ngIf="user.get('userName').touched && user.get('userName').hasError('pattern')">
            {{ 'COMPANY.LABEL.EMAIL_USERNAME' | translate }}
          </mat-error>
          <mat-error *ngIf="user.get('userName').touched && user.get('userName').hasError('invalidUser')">
            {{ 'COMPANY.LABEL.INVALID_USER' | translate }}
          </mat-error>
        </mat-form-field>
        <div class="login-error">
          <mat-error *ngIf="loginError">
            {{ 'LOGIN.MESSAGE.INVALID_USER_PASSWORD' | translate }}
          </mat-error>
        </div>
        <div class="login-btn">
          <button mat-raised-button class="btnPrimary signupbtn" [disabled]="user.invalid">Generate Password
            <!-- {{'COMPANY.LABEL.GENERATE_PASSWORD' | translate }} -->
          </button>
        </div>
      </form>
      <form *ngIf="pageToShow == 'EMAIL_CONFIRM_PAGE'">
        <div class="textCenter">
          <span class="icon-cancel1"></span>
        </div>
        <div class="textCenter">
          <p> Password Reset Email Sent</p>
        </div>
        <div class="textCenter">
          <span>{{emailSentMsg}}</span>
        </div>
        <div class="login-btn">
          <button mat-raised-button class="btnPrimary signupbtn" (click)="done()">
            Done</button>
        </div>
      </form>
      <form *ngIf="pageToShow == 'SSO_USER_PAGE'">
        <div class="textCenter">
          <span class="text-size">Please contact your organization's administrator for help with resetting your password.</span>
        </div>
        <div class="login-btn">
          <button mat-raised-button class="btnPrimary signupbtn" (click)="done()">
            Ok</button>
        </div>
      </form>
    </mat-card>
  </div>
  <ng-template #showProccessing>
    <div class="waitlogo">
      <div class="circle-placeholder" [ngClass]="(showWaitLogo)?'blur-in':'blur-out'">
        <div class="logo-circle">
          <img src="assets/images/procurant_logo.png" alt="Procurant" />
        </div>

      </div>
      <div class="company" [ngClass]="(showWaitLogo)?'text-trackin blur-in':'text-trackout blur-out'">
        <h1>Procurant</h1>
      </div>
    </div>
  </ng-template>
</div>