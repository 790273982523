import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { DialogBoxService } from './services/dialog-box.service';
import { MaterialUIModule } from '../material-ui/material-ui.module';



@NgModule({
    declarations: [
        ConfirmDialogComponent
    ],
    imports: [
        CommonModule,
        MaterialUIModule
    ],
    providers: [
        DialogBoxService
    ]
})
export class SharedComponentsModule { }
