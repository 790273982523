import { Component, OnInit, ViewChild } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Subscription, forkJoin } from 'rxjs';
import { IMAGES } from '../../../../../environments/config.all';
import { OneAuthService, OneNotifyService } from 'one-auth';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { DialogBoxService } from '../../../shared-components/services/dialog-box.service';

@Component({
  selector: 'app-connect-registration',
  templateUrl: './connect-registration.component.html',
  styleUrls: ['./connect-registration.component.scss']
})
export class ConnectRegistrationComponent implements OnInit {
  public IMAGES = IMAGES;
  private breakpoint: Subscription;
  public isVertical: boolean = false;
  public allApps: any[];
  isDisableStep_2 = true;
  isDisableStep_3 = true;
  isDisableStep_1 = false;
  selectedIndex = 0;
  orgBussinessTypes = [];
  countryList: any[];
  statesList: any[];
  registrationForm: FormGroup;
  appSelectionForm: FormGroup;
  isNextEnable = false;
  requestJSON: any;
  public stepstatus = {
    stepone: false,
    steptwo: false
  };
  public editable = true;
  serviceTypeList = [];
  companyReadOnly = false;

  @ViewChild('stepper') public stepper: MatStepper;

  isCreate = false;
  constructor(public notify: OneNotifyService, public translate: TranslateService, public auth: OneAuthService, public dialogRef: MatDialogRef<ConnectRegistrationComponent>, private fb: FormBuilder, private breakpointObserver: BreakpointObserver, public dialogService: DialogBoxService) { }


  ngOnInit(): void {
    this.createEmptyForm();
    this.getLookupData();
  }

  createEmptyForm() {
    this.registrationForm = this.fb.group({
      fname: ['', Validators.compose([Validators.required, Validators.pattern(this.auth.aplhaNumeric)])],
      lname: ['', Validators.compose([Validators.required, Validators.pattern(this.auth.aplhaNumeric)])],
      countryCode: ['', Validators.required],
      phone: ['', Validators.compose([Validators.required, Validators.pattern(this.auth.phonePattern)])],
      email: ['', Validators.compose([Validators.required, Validators.pattern(this.auth.emailPattern)])],
      emailError: [false, Validators.requiredTrue],
      organizationId: [null]
    });
  }

  setVerticalStepper(isVertical: boolean) {
    if (isVertical) {
      this.isVertical = true;
    } else {
      this.isVertical = false;
    }
    //console.log(isVertical);
  }

  getLookupData() {
    this.auth.getCountryList().subscribe(q => {
      if (q instanceof Array && q.length > 0) {
        this.countryList = q;
      } else {
        this.countryList = null;
      }
    })
  }

  public selectionChange($event?: StepperSelectionEvent): void {
    if ($event.selectedIndex === 1) {
      this.selectedIndex = $event.previouslySelectedIndex;
      this.stepper.selectedIndex = $event.previouslySelectedIndex;
      // return;
    } else {
      this.selectedIndex = $event.selectedIndex;
    }


  }

  onBusinessEmailChange(v) {
    if (v) {
      this.auth.isConnectBusinessEmailCheck(v).subscribe(
        (r) => {
          if (r.status === 200) {
            this.dialogService.openConfirmDialog("Based on the email you entered we found a registered company account for <b>" + r.body.name + "</b> in Procurant. \nPlease confirm if you would like your user account to be setup under this company profile?", "Yes", "No", 700).then(res => {
              this.registrationForm.get('organizationId').setValue(r.body.id);
            })
          }
          this.registrationForm.get('emailError').setValue(true);
          this.registrationForm.get('emailError').updateValueAndValidity();
        }, err => {
          this.notify.show(err, 'error');
          this.registrationForm.get('emailError').setValue(false);
          this.registrationForm.get('emailError').updateValueAndValidity();
        });
    }
  }




  create() {
    this.isCreate = true;
    this.setrequestJSON().then(res => {
      this.auth.addConnectUser(this.requestJSON).subscribe((data) => {
        // console.log(data);
        // console.log(this.requestJSON);
        this.stepper.linear = false;
        this.stepstatus.steptwo = true;
        this.stepper.selectedIndex = 1;
        this.editable = false;
        this.stepper.linear = true;
      }, (err) => {
        this.isCreate = false;
        this.stepstatus.steptwo = false;
      });
    })
  }

  setrequestJSON() {
    return new Promise((resolve, reject) => {
      this.requestJSON = {
        "fname": this.registrationForm.get('fname').value,
        "lname": this.registrationForm.get('lname').value,
        "email": this.registrationForm.get('email').value,
        "phone": this.registrationForm.get('phone').value,
        "countryCode": this.registrationForm.get('countryCode').value,
        "organizationId": this.registrationForm.get('organizationId').value
      };
      resolve(true);
    });
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

}
