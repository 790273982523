import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
// import { OneAuthService } from '../../services/one-auth.service';
import { TranslateService } from '@ngx-translate/core';
import { timer, forkJoin, Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
// import * as carpet from 'crypto-js';
import { map } from 'rxjs/operators';

import { URLS } from '../../../../../environments/config.url';
import { IMAGES } from '../../../../../environments/config.all';
import { CompanyRegistrationComponent } from '../company-registration/company-registration.component';
import { OneAuthService, OneNotifyService } from 'one-auth';
import { ConnectRegistrationComponent } from '../connect-registration/connect-registration.component';
import * as carpet from 'crypto-js';


@Component({
  selector: 'one-login',
  templateUrl: './one-login.component.html',
  styleUrls: ['./one-login.component.scss']
})
export class OneLoginComponent implements OnInit {
  @ViewChild('bgvideo') vid: ElementRef;
  private token: string;
  public showLogin: boolean = false;
  public showWaitLogo: boolean = true;
  loginForm: FormGroup;
  loginError: boolean = false;
  apps: any;
  errorMsg = '';
  inValid = false;
  public user: any;
  private secret: string;
  private ecryptsec: string;
  private header: HttpHeaders = new HttpHeaders();
  buttonToShow = "CONTINUE";
  ssoDetails: any;
  @ViewChild('CONTINUE') CONTINUE: TemplateRef<any>;
  @ViewChild('LOGINWITHPROCURANT') LOGINWITHPROCURANT: TemplateRef<any>;
  @ViewChild('LOGINWITHSSOPROVIDER') LOGINWITHSSOPROVIDER: TemplateRef<any>;
  successMsg = '';
  warnMsg = '';
  public userValidationloginCount: number = 0;
  public loginCount: number;
  public maxAttempt: number = 5;
  constructor(public auth: OneAuthService, private cdr: ChangeDetectorRef, public dialog: MatDialog, private notify: OneNotifyService, private fb: FormBuilder, public translate: TranslateService, private http: HttpClient, private router: Router, private _Activatedroute: ActivatedRoute) {
    this.header.set('Content-Type', 'application/json');
    this.translate.setDefaultLang('en_US');
    this.loginForm = fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  private waiting;

  ngAfterViewInit() {
  }
  ngOnInit() {
    this.secret = "Go to Control Panel – User Accounts and turn User Account Control off. Restart the computer. and run the Antioch installer. You must leave User Account Control switched off while selecting a keyboard layout for Antioch, and while making any other changes to settings, and while registering the program.";
    this.checkControlIsEdit();
    this.pageLodingChanges();
    // this.showWaitLogo = true;
    // this.showLogin = false;
    // this.auth.checkLoginStatus().then((res) => {
    //   console.log(res);
    //   if (res) {
    //     // If user is already logged in take him to apps
    //     this.router.navigate(['apps']);
    //   } else {
    //     // do nothing ..continue loading
    //   }
    // });
    // // this.checkLoginStatus();
    // this.waiting = setTimeou3t(() => {
    //   this.showWaitLogo = false;
    //   let afterblurout = setTimeout(() => {
    //     this.showLogin = true;
    //   }, 1500);

    // }, 1800);

    // this.auth.loadAuthFromStorage().then(user => {
    //   this.auth.getTokenFromStorage().subscribe((token)=>{
    //     this.auth.checkUserAuthValidity().subscribe((res)=>{
    //       if(this.auth.user.permissions.length <= 1){
    //         //Everything checks out, has single app acess,redirecting directly to app to avoid flicker
    //         window.location.href = this.auth.user.permissions[0].appLink;
    //       } else{
    //         this.router.navigate(['apps']);
    //       }

    //     },(err)=>{

    //       console.log(err);
    //     });
    //   })
    // });
    // this.showLogin = true;
    // this.auth.getTokenFromStorage().subscribe(token => {
    //   this.auth.token = token;
    // });

  }

  checkControlIsEdit() {
    this.loginForm.get("username").valueChanges.subscribe(x => {
      this.errorMsg = "";
    });
    this.loginForm.get("password").valueChanges.subscribe(x => {
      this.errorMsg = "";
    });
  }

  checkref() {
    let r = this._Activatedroute.snapshot.queryParams;
    if (r['ref'] == 'joinconnect') {
      this.openConnectRegistrationDialog();
    }
  }

  pageLodingChanges() {
    this.showWaitLogo = true;
    this.showLogin = false;
    this.auth.checkLoginStatus().then((res) => {
      if (res) {
        // If user is already logged in take him to apps       
        this.router.navigate(['apps']);
      } else {
        // do nothing ..continue loading
      }
    }, err => {


    });
    // this.checkLoginStatus();
    this.waiting = setTimeout(() => {
      this.showWaitLogo = false;
      let afterblurout = setTimeout(() => {
        this.showLogin = true;
        this.checkref();
      }, 1500);

    }, 1800);
  }


  // checkLoginStatus() {
  //   this.auth.loadAuthFromStorage().then(user => {
  //     this.auth.getTokenFromStorage().subscribe((token) => {
  //       this.auth.checkUserAuthValidity().subscribe((res) => {
  //         if (this.auth.user.subApps.length <= 1) {
  //           //Everything checks out, has single app acess,redirecting directly to app to avoid flicker
  //           window.location.href = this.auth.user.permissions[0].appLink;
  //         } else {
  //           this.router.navigate(['apps']);
  //         }
  //         clearTimeout(this.waiting);
  //       }, (err) => {

  //         console.log(err);
  //       });
  //     });
  //   });
  //   clearTimeout(this.waiting);
  // }

  ngOnDestroy() {

  }

  isEmptyObject(obj) {
    return (obj && (Object.keys(obj).length === 0));
  }


  ngAfterContentInit() {
    /*ENBABLE VIDEO BG*/
    // timer(500).subscribe(() => {
    //   this.vid.nativeElement.innerHTML = "<video muted autoplay loop playsinline webkit-playsinline class='mainvideo' style='object-fit: fill;max-height: 100%;transform: translateX(0%) translateY(0);width: 100%;' #bgvideo> \
    //   <source src='assets/videos/test3.mp4' type='video/mp4' /></video>";

    // },
    //   (err) => { }
    //   ,
    //   () => {
    //     console.log('complete');
    //     document.querySelector('.video-container').classList.add('show');
    //   });
    /* VIDEO BG */
  }

  login() {
    this.loginError = false
    //console.log("Login Called")
    let un = this.loginForm.get("username").value;
    let up = this.loginForm.get("password").value;
    if (un !== undefined && un !== "" && up !== undefined && up !== "") {
      this.auth.validate(un, up).subscribe(res => {
        //console.log("Login Successful")
        this.successMsg = "Login Successful"
        this.auth.getUserInfoPermission().subscribe((ipres) => {
          this.auth.LoadAuth()
            .then((res) => {
              //console.log(res);
              if (this.auth.user.info.locale !== undefined) {
                this.translate.use(this.auth.user.info.locale);
              } else {
                this.translate.use('en_US');
              }
              if (this.auth.user.subApps != null) {
                this.auth.checkAdminPermission(this.auth.user.subApps).then(r => {
                  if (this.auth.user.subApps.length <= 1) {
                    // console.log(res);
                    // console.log(this.auth.token);
                    //Everything checks out, has single app acess,redirecting directly to app to avoid flicker
                    //this.router.navigate(['apps', 'true']);

                    window.location.href = this.auth.user.subApps[0].appLink;
                    this.auth.currentActiveApp(this.auth.user.subApps[0].name);
                  } else {
                    this.router.navigate(['apps', 'true']);
                  }
                })
              }
            }, (err) => {
              console.log(err);
              this.inValid = true;
            });
        }, (err) => {
          console.log('Encountered and error getting User Info');
          this.inValid = true;
          console.log(err);
        });
      }, (err) => {
        this.loginError = true;
        this.inValid = true;
        this.errorMsg = err.headers.get('Error');
        this.loginCount = err.headers.get('LoginCount');
        this.loginForm.get('password').setErrors({ 'invalidPassword': true });
      });
    } else {
      //wait for user to do the right thing
      this.inValid = true;
    }


  }

  checkUser() {
    this.errorMsg = '';
    let un = this.loginForm.get("username").value;
    if (un !== undefined && un !== "") {
      this.auth.checkUser(un).subscribe(res => {
        if (res) {
          this.buttonToShow = "LOGINWITHSSOPROVIDER";
          this.ssoDetails = res;
          let _sa = carpet.AES.encrypt(JSON.stringify(this.ssoDetails?.orgId), this.secret).toString();
          sessionStorage.setItem("exchangeDetail", _sa);
        } else {
          this.buttonToShow = "LOGINWITHPROCURANT";
        }
      }, (err) => {
        // this.loginError = true;
        // this.inValid = true;
        this.errorMsg = err.headers.get('Error');
        if (err.status == 502) {
          this.errorMsg = "Service is down. Please try after some time."
        } else {
          if (err.status == 401) {
            this.userValidationloginCount = err.error.LoginCount;
            this.loginForm.get('username').setErrors({ 'invalidUser': true });
          } else {
            this.loginForm.get('username').setErrors({ 'invalidUser': true });
          }
        }
        // this.buttonToShow = "LOGINWITHPROCURANT";
      });
    } else {
      this.loginForm.get('username').markAsTouched();
    }
  }

  loginWithSsoProvider(type) {
    switch (type) {
      case 'MICROSOFT':
        this.ssoMicrosoft();
        break;
      case 'OKTA':
        this.ssoOkta();
        break;
      default:
        this.ssoMicrosoft();
        break;
    }
  }

  ssoOkta() {
    let redirectUri = this.auth.getRedirectUri();
    let challengData = this.auth.genCodeChallenge('OKTA');
    if (this.ssoDetails) {
      this.warnMsg = "Authenticating";

      //uncomment below line to test locally
      //redirectUri = "http://localhost:4200/authenticate"
      //JR: Commented the tenantid and adding organizations
      // let url = this.ssoDetails.authority+this.ssoDetails.tenantId+"/oauth2/v2.0/authorize?client_id="
      // +this.ssoDetails.clientId+"&response_type=code&redirect_uri="+redirectUri+"&response_mode=query&scope="
      // +this.ssoDetails.scope+"&login_hint="+this.loginForm.get("username").value;
      // +"&state=login";



      //let url = "https://trial-7747660.okta.com/oauth2/v1/authorize?client_id=0oaanfe4gqFKayHcU697&code_challenge=zDgOVxEl_ziDMAjkc6jjhmfLiPkbLrXuJUewHRqJUWo&code_challenge_method=S256&nonce=zj2GF1NhYcOGDRFar749ynp0xZfDeGYDiLnH7EWDcdH9jw61lsLAGQ7vMK4gdA2i&redirect_uri=http://localhost:4200/authenticate&response_type=token&state=yqJ7OSVnwotkFtLJlutYudPoPFQR2QQH4asdHkwQLEqTpydtDZP13O72Fk7h358l&scope=openid%20profile%20email%20okta.users.read.self%20okta.users.manage.self%20okta.internal.enduser.read%20okta.internal.enduser.manage%20okta.enduser.dashboard.read%20okta.enduser.dashboard.manage";
      //zDgOVxEl_ziDMAjkc6jjhmfLiPkbLrXuJUewHRqJUWo
      let url = this.ssoDetails.authority + "oauth2/v1/authorize?"
        + "client_id=" + this.ssoDetails.clientId
        + "&code_challenge=" + challengData.cc
        + "&code_challenge_method=S256&nonce=" + challengData.nonce
        + "&redirect_uri=" + redirectUri
        + "&response_type=code&scope=" + this.ssoDetails.scope
        + "&login_hint=" + this.loginForm.get("username").value
        + "&prompt=login"
        + "&state=" + challengData.state;

      //console.log(url)
      window.location.href = url;
      // console.log("hi")
    }
  }

  ssoMicrosoft() {
    let redirectUri = this.auth.getRedirectUri();
    let challengData = this.auth.genCodeChallenge();
    if (this.ssoDetails) {
      this.warnMsg = "Authenticating";
      //JR: Commented the tenantid and adding organizations
      // let url = this.ssoDetails.authority+this.ssoDetails.tenantId+"/oauth2/v2.0/authorize?client_id="
      // +this.ssoDetails.clientId+"&response_type=code&redirect_uri="+redirectUri+"&response_mode=query&scope="
      // +this.ssoDetails.scope+"&login_hint="+this.loginForm.get("username").value;
      // +"&state=login";

      let url = this.ssoDetails.authority + "organizations" + "/oauth2/v2.0/authorize?client_id="
        + this.ssoDetails.clientId + "&response_type=code&redirect_uri=" + redirectUri + "&response_mode=query&scope="
        + this.ssoDetails.scope + "&login_hint=" + this.loginForm.get("username").value
        + "&code_challenge=" + challengData.cc
        + "&code_challenge_method=S256"
        + "&state=" + challengData.state;

      //console.log(url)
      window.location.href = url;
      // console.log("hi")
    }
  }

  backToContinue() {
    this.buttonToShow = "CONTINUE";
    this.loginForm.get('username').setErrors(null);
    this.loginForm.get('password').setErrors(null);
    this.errorMsg = "";
  }

  validateForm() {
    if (this.loginForm.get('username').value == '' || this.loginForm.get('password').value == '') {
      this.inValid = true;
    } else {
      this.inValid = false;
    }
  }
  // Don't need to this here becuase, we check it in init and/or validate, as necessary. The logic to forward to necessary app or allow the user to select the app should be in apps component, not here
  // getSubcribedApp() {
  //   this.auth.getSubscribedApplications().subscribe(res => {
  //     this.auth.apps = res;
  //     if (this.auth.apps.length > 1) {
  //       this.auth.apps.forEach(element => {
  //         this.appLinks(element);
  //       });
  //       this.router.navigate(['apps']);
  //     } else {
  //       this.auth.loadAuthFromStorage().then(user => {
  //         this.appLinks(this.auth.apps[0]);
  //         user.info.defalutApp = this.auth.apps[0].name;
  //         this.auth.storeAuth();
  //         // this.router.navigate([this.auth.apps[0].appLink]);
  //        // window.location.href = this.auth.apps[0].appLink; //"https://www.google.com";
  //       });

  //     }
  //     this.localStorage.setItem('subapps', JSON.stringify(this.auth.apps)).subscribe(apps => { });

  //   });
  // }

  // appLinks(element) {
  //   switch (element.applicationCode) {
  //     case "BUYER":
  //       element.IconLink = IMAGES.BUYER_APP_ICON;
  //       element.appLink = "https://localhost:4203/buyer-app/dashboard"; //Testing  //"/buyer-app/dashboard";
  //       break;
  //     case "SUPPLIER":
  //       element.IconLink = IMAGES.SUPPLIER_APP_ICON;
  //       element.appLink = "/supplier-app/dashboard";
  //       break;
  //     case "CARRIER":
  //       element.IconLink = IMAGES.CARRIER_APP_ICON;
  //       element.appLink = "/carrier-app/dashboard";
  //       break;
  //     default:
  //       element.IconLink = IMAGES.BUYER_APP_ICON;
  //       break;
  //   }
  // }

  openRegistrationDialog() {
    let dialogRef = this.dialog.open(CompanyRegistrationComponent, {
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  openConnectRegistrationDialog() {
    console.log("2");
    let dialogRef = this.dialog.open(ConnectRegistrationComponent, {
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }
}
