import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OneLoginComponent } from './modules/one-login/components/one-login/one-login.component';
import { AppSelectorComponent } from './modules/one-login/components/app-selector/app-selector.component';
import { OneLogoutComponent } from './modules/one-login/components/one-logout/one-logout.component';
import { ForgotPasswordComponent } from './modules/one-login/components/forgot-password/forgot-password.component';
import { ActivateUserComponent } from './modules/one-login/components/activate-user/activate-user.component';
import { OneAuthenticateComponent } from './modules/one-login/components/one-authenticate/one-authenticate.component';


const routes: Routes = [

    {
        path: "",
        component: OneLoginComponent,
        data: { title: 'Login' }
    },
    {
        path: "login",
        component: OneLoginComponent,
        data: { title: 'Login' }
    },
    {
        path: "authenticate",
        component: OneAuthenticateComponent
    },
    {
        path: "forgot-password",
        component: ForgotPasswordComponent,
        data: { title: 'Forgot Password' }
    },
    {
        path: 'reset-password',
        component: ActivateUserComponent,
        data: { title: 'Reset Password' }
    },
    {
        path: 'activate-user',
        component: ActivateUserComponent,
        data: { title: 'Activate User' }
    },
    {
        path: "apps",
        component: AppSelectorComponent,
        data: { title: 'My Applications' }
    },
    {
        path: "apps/:flag",
        component: AppSelectorComponent,
        data: { title: 'My Applications' }
    },
    {
        path: "logout",
        component: OneLogoutComponent,
        data: { title: 'Logout' }
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })
    ],
    exports: [
        RouterModule
    ]
})

export class AppRoutingModule { }
