import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OneAuthService } from 'one-auth';

@Component({
  selector: 'app-one-authenticate',
  templateUrl: './one-authenticate.component.html',
  styleUrls: ['./one-authenticate.component.scss']
})
export class OneAuthenticateComponent implements OnInit {

  ssoCode: string;

  constructor(private _Activatedroute: ActivatedRoute, public auth: OneAuthService, public translate: TranslateService, private router: Router) { }

  ngOnInit(): void {
    this._Activatedroute.queryParams.subscribe(params => {
      this.ssoCode = params["code"];
      this.callApiForAccess();
      console.log(this.ssoCode)
    });
  }


  callApiForAccess() { 
    this.auth.exchangeToken(this.ssoCode).subscribe(res => {
      //console.log(res)
      sessionStorage.removeItem("exchangeDetail");
      this.auth.getUserInfoPermission().subscribe((ipres) => {
        this.auth.LoadAuth()
          .then((res) => {
            //console.log(res);
            if (this.auth.user.info.locale !== undefined) {
              this.translate.use(this.auth.user.info.locale);
            } else {
              this.translate.use('en_US');
            }
            if (this.auth.user.subApps != null) {
              if (this.auth.user.subApps.length <= 1) {
                //Everything checks out, has single app acess,redirecting directly to app to avoid flicker
                window.location.href = this.auth.user.subApps[0].appLink;
              } else {
                this.router.navigate(['apps']);
              }
            }
          }, (err) => {
            //console.log(err);
            // this.inValid = true;
            // this.router.navigate(['reset-password']);
          });
      }, (err) => {
        console.log('Encountered and error getting User Info');
        // this.inValid = true;
        console.log(err);
      });
    }, (err) => {
      // this.loginError = true;
      // this.inValid = true;
      // this.errorMsg = err.headers.get('Error');
    });
  }

}
