import { Component, Inject, OnInit, NgModule, Pipe, PipeTransform } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser'

@Pipe({ name: 'safeHtml'})
export class SafeHtmlPipe implements PipeTransform  {
  constructor(private sanitized: DomSanitizer) {}
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}

@Component({
  selector: 'app-admin-messages-overlay',
  templateUrl: './admin-messages-overlay.component.html',
  styleUrls: ['./admin-messages-overlay.component.scss']
})
export class AdminMessagesOverlayComponent implements OnInit {

  messageList = [];
 
  constructor(public dialogRef: MatDialogRef<AdminMessagesOverlayComponent>, @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    this.messageList = this.data;
  }
  dismissAllMessages() {
    this.dialogRef.close();
  }

}
