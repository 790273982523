<div [formGroup]="registrationForm">

  <div class="registration-dialog">
    <div class="dialog-header">
      <div fxLayout="row" style="align-self: flex-end">
        <a href="javascript:void(0);" (click)="onCancelClick()">
          <mat-icon>close</mat-icon>
        </a>
      </div>
      <h2 mat-dailog-title class="logoprimary">Procurant <span class="logosecondary">One</span>
        {{ 'LOGIN.REGISTRATION' | translate }}</h2>

      <caption>{{ 'LOGIN.PROCURANT_DETAILS' | translate }}</caption>
    </div>
    <mat-dialog-content>
      <form class="mat-dialog-content">
        <ng-template [ngIf]="!isVertical" [ngIfElse]="verticalStepper" #horizontalStepper>
          <mat-horizontal-stepper #stepper linear class="horizontalStepper" [selectedIndex]="this.selectedIndex"
            (selectionChange)="selectionChange($event)">
            <!--   <mat-step label="{{ 'LOGIN.CHOOSE_APPLICATION' | translate }}" [completed]="stepstatus.stepone" [editable]="editable">
              <ng-container *ngTemplateOutlet="chooseSub"></ng-container>
            </mat-step> -->
            <mat-step label="{{ 'LOGIN.COMPANY_DETAILS' | translate }}" [completed]="stepstatus.steptwo"
              [editable]="editable">
              <ng-container *ngTemplateOutlet="companyDetails"></ng-container>
            </mat-step>
            <mat-step label="{{ 'LOGIN.CONFIRMATION' | translate }}">
              <ng-container *ngTemplateOutlet="finalizeSub"></ng-container>
            </mat-step>
          </mat-horizontal-stepper>
        </ng-template>

        <ng-template #verticalStepper>
          <mat-vertical-stepper #stepper linear class="verticalStepper">
            <!--   <mat-step label="{{ 'LOGIN.CHOOSE_APPLICATION' | translate }}" [completed]="stepstatus.stepone" [editable]="editable">
              <ng-container *ngTemplateOutlet="chooseSub"></ng-container>
            </mat-step> -->
            <mat-step label="{{ 'LOGIN.COMPANY_DETAILS' | translate }}" [completed]="stepstatus.steptwo"
              [editable]="editable">
              <ng-container *ngTemplateOutlet="companyDetails"></ng-container>
            </mat-step>
            <mat-step label="{{ 'LOGIN.CONFIRMATION' | translate }}">
              <ng-container *ngTemplateOutlet="finalizeSub"></ng-container>
            </mat-step>
          </mat-vertical-stepper>
        </ng-template>
      </form>
    </mat-dialog-content>
  </div>

  <ng-template #chooseSub matStepperIcon="edit">
    <div class="intro">{{ 'LOGIN.REGISTRATION_INTRO' | translate }}</div>
    <div class="stepContent appSelect">

      <div class="app-placeholder" *ngFor="let app of allApps" (click)="setSelectedApps(app)">
        <div class="app-block elevation">
          <div class="mask" [ngClass]="{'selected': app.isSelected, 'not-selected': !app.isSelected}">
            <mat-icon *ngIf="app.isSelected">check</mat-icon>
          </div>
          <div class="app-icon">
            <img src="{{app.IconLink}}" />
          </div>
          <div class="app-name">
            {{ app.name }}
          </div>
        </div>
      </div>
      <!-- <div style="width: 100%;float: right;text-align: center;margin-top: 15px;">
        <button type="button" mat-raised-button class="btnPrimary" (click)="appSelectionSubmit(stepper)">{{'BUTTON.NEXT' |translate}}</button>
      </div> -->
    </div>
    <div class="mat-stepper-buttons">
      <button type="button" mat-flat-button class="btnPrimary"
        (click)="appSelectionSubmit(stepper)">{{'BUTTON.NEXT' |translate}}</button>
    </div>
  </ng-template>
  <ng-template #companyDetails matStepperIcon="edit">
    <div class="intro">{{ 'LOGIN.COMPANY_DETAILS_INTRO' | translate }}</div>
    <div class="stepContent companyDetails">
      <!-- <div class="login"> -->
      <div class="grp">
        <div class="grp-header">{{ 'COMPANY.LABEL.EMAIL_ID_USERNAME' | translate }}</div>
        <div class="grp-fields">
          <mat-form-field>
            <input matInput formControlName="useremail"
              (blur)="onUserEmailChange(registrationForm.controls['useremail'].value)"
              placeholder="{{ 'COMPANY.LABEL.EMAIL' | translate }}*">
            <mat-error
              *ngIf="registrationForm.controls['useremail'].touched && registrationForm.controls['useremail'].hasError('required')">
              {{ 'COMPANY.MESSAGE.EMAIL_REQUIRED_ERROR' | translate }}
            </mat-error>
            <mat-error
              *ngIf="registrationForm.controls['useremail'].touched && registrationForm.controls['useremail'].hasError('pattern')">
              {{ 'COMPANY.MESSAGE.EMAIL_VALID_ERROR' | translate }}
            </mat-error>
            <mat-error
              *ngIf="!registrationForm.controls['useremail'].hasError('required') && registrationForm.controls['useremail'].hasError('emailExist')">
              {{ 'COMPANY.MESSAGE.EMAIL_EXIST_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
          <!-- <mat-form-field>
            <input matInput autocomplete="new-password" placeholder="{{ 'COMPANY.LABEL.PASSWORD' | translate }}*"
              formControlName="password" (keyup)="areMatch()" type="password" maxlength="64">
            <mat-error
              *ngIf="registrationForm.get('password').touched && registrationForm.get('password').hasError('required')">
              {{ 'COMPANY.MESSAGE.PASSWORD_REQUIRED_ERROR' | translate }}
            </mat-error>
            <mat-error
              *ngIf="registrationForm.get('password').touched && registrationForm.get('password').hasError('minlength')">
              {{ 'COMPANY.MESSAGE.PASSWORD_LENGTH_ERROR' | translate }}
            </mat-error>
            <mat-error
              *ngIf="!registrationForm.get('password').hasError('minlength') && registrationForm.get('password').hasError('pattern')">
              {{ 'COMPANY.MESSAGE.PASSWORD_VAILD_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input matInput maxlength="64" formControlName="confirmPassword" (keyup)="areMatch()"
              placeholder="{{ 'COMPANY.LABEL.REENTER_PASSWORD' | translate }}*" type="password" maxlength="64">
            <mat-error
              *ngIf="registrationForm.get('confirmPassword').touched && registrationForm.get('confirmPassword').hasError('required')">
              {{ 'COMPANY.MESSAGE.CONFORM_PASSWORD_REQUIRED_ERROR' | translate }}
            </mat-error>
            <mat-error
              *ngIf="registrationForm.get('confirmPassword').touched && !registrationForm.get('confirmPassword').hasError('required') && registrationForm.get('confirmPassword').hasError('isPasswordNotMatch')">
              {{ 'COMPANY.MESSAGE.PASSWORD_MISSMATCH_ERROR' | translate }}
            </mat-error>
          </mat-form-field> -->

        </div>
      </div>
      <!-- </div> -->

      <div class="grp">
        <div class="grp-header">{{ 'COMPANY.LABEL.BUSINEES_DETAILS' | translate }}</div>
        <div class="grp-fields details">
          <mat-form-field>
            <input matInput formControlName="name" (blur)="onNameChange(registrationForm.controls['name'].value)"
              placeholder="{{ 'COMPANY.LABEL.NAME' | translate }}*">

            <mat-error
              *ngIf="registrationForm.controls['name'].touched && registrationForm.controls['name'].hasError('required')">
              {{ 'COMPANY.MESSAGE.NAME_ERROR' | translate }}
            </mat-error>
            <mat-error
              *ngIf="registrationForm.controls['name'].touched && registrationForm.controls['name'].hasError('pattern')">
              {{ 'COMPANY.MESSAGE.NAME_VALID_ERROR' | translate }}
            </mat-error>
            <mat-error
              *ngIf="!registrationForm.controls['name'].hasError('required') && registrationForm.controls['name'].hasError('nameExist')">
              {{ 'COMPANY.MESSAGE.NAME_EXIST_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input matInput formControlName="url" placeholder="{{ 'COMPANY.LABEL.WEBSITE' | translate }}">
            <mat-error
              *ngIf="registrationForm.controls['url'].touched && registrationForm.controls['url'].hasError('pattern')">
              {{ 'BUSINESS_UNIT.MESSAGE.URL_VALID_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-select formControlName="bizType"
              placeholder="{{ 'COMPANY.LABEL.SELECT_COMPANY_USER_TYPE' | translate }}"
              (selectionChange)="getServiceProvider($event.value)" disableOptionCentering>
              <mat-option *ngFor="let type of orgBussinessTypes" [value]="type.code">{{type.name}}</mat-option>

            </mat-select>
            <mat-error
              *ngIf="registrationForm.controls.bizType.touched && registrationForm.controls.bizType.hasError('required')">
              {{ 'COMPANY.MESSAGE.TYPE_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input matInput formControlName="email"
              (blur)="onBusinessEmailChange(registrationForm.controls['email'].value)"
              placeholder="{{ 'COMPANY.LABEL.BUSINNES_EMAIL' | translate }}">
            <mat-error
              *ngIf="registrationForm.controls['email'].touched && registrationForm.controls['email'].hasError('pattern')">
              {{ 'COMPANY.MESSAGE.EMAIL_VALID_ERROR' | translate }}
            </mat-error>
            <mat-error
              *ngIf="registrationForm.controls['email'].touched && registrationForm.controls['email'].hasError('bizEmailExist')">
              {{ 'COMPANY.MESSAGE.EMAIL_EXIST_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field *ngIf="registrationForm.controls['bizType'].value == 'SERVICE_PROVIDER'">
            <mat-select formControlName="serviceTypeCode" placeholder="Service Provider Type" disableOptionCentering>
              <mat-option *ngFor="let type of serviceTypeList" [value]="type.code">{{type.name}}</mat-option>
            </mat-select>
            <mat-error
              *ngIf="registrationForm.controls.serviceTypeCode.touched && registrationForm.controls.serviceTypeCode.hasError('required')">
              {{ 'COMPANY.MESSAGE.SERVICE_TYPE_ERROR' | translate }}
            </mat-error>
          </mat-form-field>

        </div>
      </div>

      <div class="grp">
        <div class="grp-header">{{ 'COMPANY.LABEL.ADDRESS_DETAILS' | translate }}</div>
        <div class="grp-fields details">
          <mat-form-field>
            <input matInput formControlName="address" maxlength="155"
              placeholder="{{ 'COMPANY.LABEL.ADDRESS_LINE_1' | translate }}*">
            <mat-error
              *ngIf="registrationForm.controls['address'].touched && registrationForm.controls['address'].hasError('required')">
              {{ 'BUSINESS_UNIT.MESSAGE.ADDRESS1_ERROR' | translate }}
            </mat-error>
            <mat-error
              *ngIf="registrationForm.controls['address'].touched && registrationForm.controls['address'].hasError('pattern')">
              {{ 'BUSINESS_UNIT.MESSAGE.ADDRESS1_VALID_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input matInput formControlName="address2" maxlength="155"
              placeholder="{{ 'COMPANY.LABEL.ADDRESS_LINE_2' | translate }}">
            <mat-error
              *ngIf="registrationForm.controls['address2'].touched && registrationForm.controls['address2'].hasError('pattern')">
              {{ 'BUSINESS_UNIT.MESSAGE.ADDRESS1_VALID_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input matInput formControlName="city" placeholder="{{ 'COMPANY.LABEL.CITY' | translate }}">
            <mat-error
              *ngIf="registrationForm.controls['city'].touched && registrationForm.controls['city'].hasError('pattern')">
              {{ 'BUSINESS_UNIT.MESSAGE.CITY_VALID_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input matInput formControlName="zipCode" maxlength="10"
              placeholder="{{ 'COMPANY.LABEL.ZIP' | translate }}">
            <mat-error
              *ngIf="registrationForm.controls['zipCode'].touched && registrationForm.controls['zipCode'].hasError('pattern')">
              {{ 'BUSINESS_UNIT.MESSAGE.ZIP_VALID_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-select formControlName="countryCode" placeholder="{{ 'COMPANY.LABEL.COUNTRY' | translate }}"
              (selectionChange)="loadStatesListByCountryCode($event.value)" disableOptionCentering>
              <mat-option *ngFor="let country of countryList" [value]="country.countryCode">{{country.countryName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-select formControlName="stateCode" placeholder="{{ 'COMPANY.LABEL.STATE_REGION' | translate }}"
              disableOptionCentering>
              <mat-option *ngFor="let state of statesList" [value]="state.stateKey.stateCode">{{state.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="grp">
        <div class="grp-header">{{ 'COMPANY.LABEL.CONTACT_PERSON_DETAILS' | translate }}</div>
        <div class="grp-fields details">
          <mat-form-field>
            <input matInput formControlName="fname" maxlength="64"
              placeholder="{{ 'COMPANY.LABEL.FIRST_NAME' | translate }}">
            <mat-error
              *ngIf="registrationForm.controls['fname'].touched && registrationForm.controls['fname'].hasError('required')">
              {{ 'COMPANY.MESSAGE.FIRST_NAME_ERROR' | translate }}
            </mat-error>
            <mat-error
              *ngIf="registrationForm.controls['fname'].touched && registrationForm.controls['fname'].hasError('pattern')">
              {{ 'COMPANY.MESSAGE.FIRST_NAME_VALID_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input matInput formControlName="lname" maxlength="64"
              placeholder="{{ 'COMPANY.LABEL.LAST_NAME' | translate }}">
            <mat-error
              *ngIf="registrationForm.controls['lname'].touched && registrationForm.controls['lname'].hasError('required')">
              {{ 'COMPANY.MESSAGE.LAST_NAME_ERROR' | translate }}
            </mat-error>
            <mat-error
              *ngIf="registrationForm.controls['lname'].touched && registrationForm.controls['lname'].hasError('pattern')">
              {{ 'COMPANY.MESSAGE.LAST_NAME_VALID_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-select formControlName="usercountryCode" placeholder="{{'COMPANY.LABEL.COUNTRY_CODE'|translate}}*"
              disableOptionCentering>
              <mat-option *ngFor="let code of countryList" [value]="code.countryCode">{{code.shortName}}
                : {{code.callingCode}}</mat-option>
            </mat-select>
            <mat-error
              *ngIf="registrationForm.controls['usercountryCode'].touched && registrationForm.controls['usercountryCode'].hasError('required')">
              {{ 'COMPANY.MESSAGE.COUNTRY_CODE_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input matInput formControlName="phone" maxlength="14"
              placeholder="{{ 'COMPANY.LABEL.PHONE_NUMBER' | translate }}*">
            <mat-error
              *ngIf="registrationForm.controls['phone'].touched && registrationForm.controls['phone'].hasError('required')">
              {{ 'COMPANY.MESSAGE.PHONE_NUMBER_REQUIRED_ERROR' | translate }}
            </mat-error>
            <mat-error
              *ngIf="registrationForm.controls['phone'].touched && registrationForm.controls['phone'].hasError('pattern')">
              {{ 'COMPANY.MESSAGE.PHONE_NUMBER_VALID_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <!-- <div style="width:100%;float: left;">
        <mat-checkbox formControlName="termAndCondition">
          {{ 'COMPANY.LABEL.TERMS_CONDITIONS1' | translate }} {{ 'COMPANY.LABEL.TERMS_CONDITIONS2' | translate }}
        </mat-checkbox>
        <mat-error *ngIf="registrationForm.controls['termAndCondition'].touched && registrationForm.controls['termAndCondition'].hasError('required')">
          {{ 'COMPANY.MESSAGE.TERM_CONDITION_ERROR' | translate }}
        </mat-error>
        <mat-error *ngIf="registrationForm.controls['termAndCondition'].touched && registrationForm.controls['termAndCondition'].hasError('pattern')">
          {{ 'COMPANY.MESSAGE.TERM_CONDITION_ERROR' | translate }}
        </mat-error>
      </div> -->
      <!-- <div style="margin-top: 30px;">
        <button mat-button type="button" class="btnSecondary" (click)="cancel()">{{ 'COMPANY.LABEL.CANCEL' | translate }}</button>
        <button mat-raised-button type="button" class="btn btnPrimary" [disabled]="registrationForm.invalid || isCreate" (click)="create()">{{'COMPANY.LABEL.SUBMIT' |translate}}</button>
      </div> -->

    </div>
    <div class="terms">
      <!-- <div> -->
      <mat-checkbox formControlName="termAndCondition">
        {{ 'COMPANY.LABEL.TERMS_CONDITIONS1' | translate }} {{ 'COMPANY.LABEL.TERMS_CONDITIONS2' | translate }}
      </mat-checkbox>
      <mat-error
        *ngIf="registrationForm.controls['termAndCondition'].touched && registrationForm.controls['termAndCondition'].hasError('required')">
        {{ 'COMPANY.MESSAGE.TERM_CONDITION_ERROR' | translate }}
      </mat-error>
      <mat-error
        *ngIf="registrationForm.controls['termAndCondition'].touched && registrationForm.controls['termAndCondition'].hasError('pattern')">
        {{ 'COMPANY.MESSAGE.TERM_CONDITION_ERROR' | translate }}
      </mat-error>
      <!-- </div> -->
    </div>
    <div class="mat-stepper-buttons">
      <button mat-stroked-button type="button" class="regcancel btnSecondary"
        (click)="cancel()">{{ 'COMPANY.LABEL.CANCEL' | translate }}</button>
      <button mat-flat-button type="button" class="btnPrimary" [disabled]="registrationForm.invalid || isCreate"
        (click)="create()">{{'COMPANY.LABEL.SUBMIT' |translate}}</button>
    </div>
  </ng-template>
  <ng-template #finalizeSub mat-StepperIcon="done">
    <div class="intro" style="font-size: 20px;">{{ 'COMPANY.LABEL.THANKS_FOR_REGISTERING' | translate }}
      {{ 'COMPANY.LABEL.WITH_US' | translate }}</div>
    <div class="stepContent companyDetails">
      <div><img src='assets/images/thank-you.png' /></div>
      <div style="width: 100%;text-align: center;margin: 25px 0 5px 0;font-size: 16px;">
        {{ 'COMPANY.LABEL.REGISTRATION_SUCCESS_MSG_1' | translate }}</div>
      <div>{{ 'COMPANY.LABEL.REGISTRATION_SUCCESS_MSG_2' | translate }}</div>
    </div>




  </ng-template>
</div>
<!--Registration-->
<!-- <div class="contentRegWrapper">
  <div class="RegistrationPanel" fxFlex="100" fxFlex.xs="100">
    <div fxFlex="90" class="regRight">
      <div class="regContent">
        <mat-horizontal-stepper labelPosition="bottom" linear #stepper>
          <mat-step>
            <div class="wizardDescription">
              <div class="subText mb-10">{{ 'COMPANY.LABEL.PROCURANT_INFO' | translate }}</div>

            </div>
            <ng-template matStepLabel>{{ 'COMPANY.LABEL.CHOOSE_SUBSCRIPTION' | translate }}</ng-template>
            <div class="app-selector-container">
              <div class="apps">
                <div class="app-placeholder" *ngFor="let app of auth.apps">
                  <div class="app-block elevation">
                    <div class="app-icon">
                      <img src="{{app.IconLink}}" />
                    </div>
                    <div class="app-name">
                      {{ app.name }}
                    </div>
                  </div>
                  <div class="default-sel">
                    Make this default app
                  </div>
                </div>
              </div>
            </div>
          </mat-step>
          <mat-step>
            <div class="wizardDescription">
              <div class="subText mb-10">{{ 'COMPANY.LABEL.TELL_US_ABOUT' | translate }}</div>
              <div class="headText">{{ 'COMPANY.LABEL.YOUR_COMPANY' | translate }}</div>
            </div>
            <ng-template matStepLabel>{{ 'COMPANY.LABEL.TELL_US_ABOUT_YOUR_PROFILE' | translate }}</ng-template>
            <div fxLayout="row" fxLayout.xs="column" fxFlexFill fxLayoutAlign="center center" class="mb-40">
              <div fxFlex="100">
                <div fxLayout="column" fxFlex="49" fxFlexFill>
                  <div fxFlex="100">
                    <h3>{{ 'COMPANY.LABEL.BUSINEES_DETAILS' | translate }}</h3>
                    <div class="formContainer halfFields">
                      <mat-form-field>
                        <input matInput placeholder="{{ 'COMPANY.LABEL.NAME' | translate }}*">

                      </mat-form-field>
                      <mat-form-field>
                        <input matInput placeholder="{{ 'COMPANY.LABEL.WEBSITE' | translate }}">

                      </mat-form-field>
                    </div>
                    <div class="formContainer halfFields">
                      <mat-form-field>
                        <mat-select placeholder="{{ 'COMPANY.LABEL.SELECT_COMPANY_USER_TYPE' | translate }}" disableOptionCentering>
                          <mat-option>fdsafdsafs</mat-option>
                          <mat-option>fdsafdsafs</mat-option>
                        </mat-select>

                      </mat-form-field>
                      <mat-form-field>
                        <input matInput placeholder="{{ 'COMPANY.LABEL.BUSINNES_EMAIL' | translate }}">

                      </mat-form-field>
                    </div>

                  </div>
                  <div fxFlex="100">
                    <h3>{{ 'COMPANY.LABEL.ADDRESS_DETAILS' | translate }}</h3>
                    <div class="formContainer halfFields">
                      <mat-form-field>
                        <input matInput maxlength="155" placeholder="{{ 'COMPANY.LABEL.ADDRESS_LINE_1' | translate }}*">
                      </mat-form-field>
                      <mat-form-field>
                        <input matInput maxlength="155" placeholder="{{ 'COMPANY.LABEL.ADDRESS_LINE_2' | translate }}">

                      </mat-form-field>
                      <mat-form-field>
                        <input matInput placeholder="{{ 'COMPANY.LABEL.CITY' | translate }}">

                      </mat-form-field>
                      <mat-form-field>
                        <input matInput maxlength="10" placeholder="{{ 'COMPANY.LABEL.ZIP' | translate }}">

                      </mat-form-field>
                      <mat-form-field>
                        <mat-select placeholder="{{ 'COMPANY.LABEL.COUNTRY' | translate }}" disableOptionCentering>
                          <mat-option>fdsafdsaf</mat-option>
                          <mat-option>fdsafdsaf</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field>
                        <mat-select placeholder="{{ 'COMPANY.LABEL.STATE_REGION' | translate }}" disableOptionCentering>
                          <mat-option>fdsafdsfds</mat-option>
                          <mat-option>fdsafdsfds</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div fxLayout="column" fxFlex="2" fxFlexFill class="verticalDivider">
                </div>
                <div fxLayout="column" fxFlex="49" fxFlexFill>
                  <div fxFlex="100">
                    <h3>{{ 'COMPANY.LABEL.CONTACT_PERSON_DETAILS' | translate }}</h3>
                    <div class="formContainer halfFields">
                      <mat-form-field>
                        <input matInput maxlength="64" placeholder="{{ 'COMPANY.LABEL.FIRST_NAME' | translate }}">

                      </mat-form-field>
                      <mat-form-field>
                        <input matInput maxlength="64" placeholder="{{ 'COMPANY.LABEL.LAST_NAME' | translate }}">

                      </mat-form-field>
                      <mat-form-field>
                        <mat-select placeholder="{{'COMPANY.LABEL.COUNTRY_CODE'|translate}}*" disableOptionCentering>
                          <mat-option>fdsafdsaf</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field>
                        <input matInput maxlength="14" placeholder="{{ 'COMPANY.LABEL.PHONE_NUMBER' | translate }}*">

                      </mat-form-field>
                    </div>
                  </div>
                  <div fxFlex="100">
                    <h3>{{ 'COMPANY.LABEL.LOGIN_CREDENTIALS' | translate }}</h3>
                    <div class="formContainer halfFields">
                      <mat-form-field>
                        <input matInput placeholder="{{ 'COMPANY.LABEL.EMAIL' | translate }}*">

                      </mat-form-field>
                      <mat-form-field>
                        <input matInput placeholder="{{ 'COMPANY.LABEL.PASSWORD' | translate }}*" type="password" maxlength="64">

                      </mat-form-field>
                    </div>
                    <div class="formContainer halfFields">
                      <mat-form-field>
                        <input matInput placeholder="{{ 'COMPANY.LABEL.REENTER_PASSWORD' | translate }}*" type="password" maxlength="64">

                      </mat-form-field>
                    </div>
                    <div fxLayout="row">
                      <mat-error>
                        {{ 'COMPANY.MESSAGE.PASSWORD_MISSMATCH_ERROR' | translate }}
                      </mat-error>
                    </div>
                    <div fxLayout="row">
                      <mat-checkbox>
                        <span style="white-space:normal">{{ 'COMPANY.LABEL.TERMS_CONDITIONS1' | translate }}</span>
                        <br />
                        <span style="white-space:normal">{{ 'COMPANY.LABEL.TERMS_CONDITIONS2' | translate }}</span>
                      </mat-checkbox>
                    </div>
                  </div>
                  <div fxFlex="100">

                  </div>
                </div>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center">
              <button mat-button class="btn btnSecondary">{{ 'COMPANY.LABEL.CANCEL' |
                              translate }}</button>
              <button mat-button class="btn btnPrimary">{{
                              'COMPANY.LABEL.SUBMIT' |
                              translate
                              }}</button>
            </div>
          </mat-step>
          <mat-step>
            <div class="wizardDescription">
              <div class="subText mb-10">{{ 'COMPANY.LABEL.THANKS_FOR_REGISTERING' | translate }}</div>
              <div class="headText">{{ 'COMPANY.LABEL.WITH_US' | translate }}</div>
            </div>
            <ng-template matStepLabel>{{ 'COMPANY.LABEL.DONE' | translate }}</ng-template>
            <div class="wizardDescription finalStep" fxLayout="column" fxLayoutAlign="center center">
              <img src='../../../../../assets/images/thank-you.png' />
              <div class="headText fs-24">{{ 'COMPANY.LABEL.REGISTRATION_SUCCESS_MSG_1' | translate }}</div>
              <div class="subText fs-20">{{ 'COMPANY.LABEL.REGISTRATION_SUCCESS_MSG_2' | translate }}</div>
            </div>
          </mat-step>
        </mat-horizontal-stepper>
      </div>
    </div>
  </div>
</div> -->