import { Injectable, Inject } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, retry } from 'rxjs/operators';
import { OneAuthService } from 'one-auth';
// import { AppAuthService } from './app-auth.service';

// import { AlertMsgService } from './alert-msg.serivce';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';




export const skipTokenHeader = 'X-Skip-Interceptor';
export const skipJSONContentTypeHeader = 'Content-Type';


@Injectable({
    providedIn: 'root'
})

export class HttpRequestInterceptor implements HttpInterceptor {
    constructor(private auth: OneAuthService, @Inject(DOCUMENT) document: any, public route: Router, private translate: TranslateService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // console.log(request.headers.has('X-Skip-Interceptor'));
        // console.log(request.headers);
        if (request.headers.has(skipTokenHeader)) {
            // console.log
            // console.log(request);
            return next.handle(request);
        }
        else {
            // console.log(request);
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.auth.getToken()}`
                }
            });
        }
        return next.handle(request).pipe(
           // retry(1),
            // map((event: HttpEvent<any>)=>{
            //     if(event instanceof HttpResponse){
            //         console.log('event: ', event);
            //     }
            //     return event;
            // }),
            catchError((error: HttpErrorResponse) => {
                let url: string = document.location.href;
                let ae = this.translate.instant('LANG.AUTH_ERROR');
                let se = this.translate.instant('LANG.SERVICE_ERROR');
                let errmsg = "";
                // console.log(error);
                // let errordetails = {};
                // errordetails = {
                //     reason: error && error.error.error ? error.error.error: '',
                //     message: error && error.error.message ? error.error.message: '',
                //     status: error.status
                // }
                if (error.status == 200) {

                }
                if (error.status == 401 && !url.includes('localhost')) {

                    //Show Authentication Error, Return back to login
                    // this.route.navigate(['/login'])
                    // Auth Issue, navigate to login
                    // console.log('navigate to login');

                    errmsg = this.translate.instant('LANG.AUTH_ERROR_MSG') + "!";
                    //this.auth.logoutUser();
                    //this.auth.clearSession();
                    // this.alert.messageAlert(ae, errmsg, ['ok']);
                    //this.route.navigateByUrl('/');
                    // this.navCtrl.navigateRoot('user-login');
                } else if (error.status == 400) {
                    errmsg = this.translate.instant('LANG.TOKEN_ERROR_MSG') + "!";
                    // this.alert.messageAlert(ae, errmsg, ['ok']);
                } else if (error.status == 500) {
                    // Bad Request / Internal Server Error
                    errmsg = this.translate.instant('LANG.SERVICE_ERROR_MSG') + "!";
                    // this.alert.messageAlert(se, errmsg, ['ok']);
                } else if (error.status == 404) {
                    // API Service not found
                    errmsg = this.translate.instant('LANG.SERVICE_NOTFOUND_MSG') + "!";
                    // this.alert.messageAlert(se, errmsg, ['ok']);
                } else if (error.status == 502) {
                    // API Service is down
                    errmsg = this.translate.instant('LANG.SERVICE_DOWN_MSG') + "!";
                    // this.alert.messageAlert(se, errmsg, ['ok']);
                    // return false;
                }
                return throwError(error);
                // return throwError(error);
            })
        );
    }

}