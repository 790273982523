import { Component, OnInit, createPlatformFactory, ViewChild } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Subscription, forkJoin } from 'rxjs';
// import { OneAuthService } from '../../services/one-auth.service';
// import { IMAGES } from '../../../../../environment/config.all';
import { URLS } from '../../../../../environments/config.url';
import { IMAGES } from '../../../../../environments/config.all';
import { OneAuthService } from 'one-auth';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'company-registration',
  templateUrl: './company-registration.component.html',
  styleUrls: ['./company-registration.component.scss']
})
export class CompanyRegistrationComponent implements OnInit {
  public IMAGES = IMAGES;
  private breakpoint: Subscription;
  public isVertical: boolean;
  public allApps: any[];
  isDisableStep_2 = true;
  isDisableStep_3 = true;
  isDisableStep_1 = false;
  selectedIndex = 0;
  orgBussinessTypes = [];
  countryList: any[];
  statesList: any[];
  registrationForm: FormGroup;
  appSelectionForm: FormGroup;
  // isPasswordMatch: boolean;
  isNextEnable = false;
  requestJSON: any;
  public stepstatus = {
    stepone: false,
    steptwo: false
  };
  public editable = true;
  serviceTypeList = [];

  @ViewChild('stepper') public stepper: MatStepper;

  isCreate = false;
  constructor(public translate: TranslateService, public auth: OneAuthService, public dialogRef: MatDialogRef<CompanyRegistrationComponent>, private fb: FormBuilder, private breakpointObserver: BreakpointObserver) { }

  ngOnInit() {

    this.getAllApps();
    this.createEmptyForm();
    /*    this.breakpoint = this.breakpointObserver.observe(['(max-width: 412px)']).subscribe((state: BreakpointState) => {
         console.log(state);
         this.setVerticalStepper(state.matches);
       }); */

    // this.localStorage.getItem('subapps').subscribe((res: any) => {
    //   this.auth.apps = JSON.parse(res);
    // });
    this.getLookupData();
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  createEmptyForm() {

    this.registrationForm = this.fb.group({
      fname: ['', Validators.compose([
        Validators.required,
        Validators.pattern(this.auth.aplhaNumeric)
      ])],
      lname: ['', Validators.compose([
        Validators.required,
        Validators.pattern(this.auth.aplhaNumeric)
      ])],
      usercountryCode: ['', Validators.required],
      phone: ['', Validators.compose([
        Validators.required,
        Validators.pattern(this.auth.phonePattern)
      ])],
      useremail: ['', Validators.compose([
        Validators.required,
        Validators.pattern(this.auth.emailPattern)
      ])],
      // password: ['', Validators.compose([
      //   Validators.minLength(8),
      //   Validators.required,
      //   Validators.pattern(this.auth.passwordPattern)
      // ])],
      // confirmPassword: ['', Validators.required],
      termAndCondition: ['', Validators.compose([
        Validators.required,
        Validators.pattern('true')
      ])],
      address: ['', Validators.compose([
        Validators.required,
        Validators.pattern(this.auth.addressLinePattern)
      ])],
      address2: ['', Validators.pattern(this.auth.addressLinePattern)],
      city: ['', Validators.pattern(this.auth.namesPattern)],
      zipCode: ['', Validators.pattern(this.auth.zipCodePattern)],
      stateCode: [''],
      businesscountryCode: [''],
      name: ['', Validators.compose([
        Validators.required,
        Validators.pattern(this.auth.namesPattern)
      ])],
      url: ['', Validators.pattern(this.auth.urlPattern)],
      bizType: ['', Validators.required],
      serviceTypeCode: [''],
      email: ['', Validators.pattern(this.auth.emailPattern)],
      countryCode: ['']
    });
  }




  setVerticalStepper(isVertical: boolean) {
    if (isVertical) {
      this.isVertical = true;
    } else {
      this.isVertical = false;
    }
    //console.log(isVertical);
  }

  setSelectedApps(selectedApps) {
    if (selectedApps.isSelected) {
      selectedApps.isSelected = false;
    } else {
      selectedApps.isSelected = true;
    }
    let f = this.allApps.filter(t => t.isSelected === true);
    //console.log(f);
    if (f.length > 0) {
      this.stepstatus.stepone = true;
    } else {
      this.stepstatus.stepone = false;
    }
  }



  getAllApps() {
    this.allApps = [
      {
        IconLink: IMAGES.CONNECT_APP_ICON,
        name: this.translate.instant('LOGIN.START'),
        isSelected: true
      },
      {
        IconLink: IMAGES.BUYER_APP_ICON,
        name: this.translate.instant('LOGIN.BUYER'),
        isSelected: false
      },
      {
        IconLink: IMAGES.SUPPLIER_APP_ICON,
        name: this.translate.instant('LOGIN.SUPPLIER'),
        isSelected: false
      },
      {
        IconLink: IMAGES.CARRIER_APP_ICON,
        name: this.translate.instant('LOGIN.CARRIER'),
        isSelected: false
      },
      {
        IconLink: IMAGES.SHARE_APP_ICON,
        name: this.translate.instant('LOGIN.TRUST'),
        isSelected: false
      },
      {
        IconLink: IMAGES.TRACE_APP_ICON,
        name: this.translate.instant('LOGIN.TRACE'),
        isSelected: false
      },
      {
        IconLink: IMAGES.INSPECT_APP_ICON,
        name: this.translate.instant('LOGIN.INSPECT'),
        isSelected: false
      }
    ]
  }

  getLookupData() {
    return forkJoin(
      [this.auth.getOrgBussinessType().subscribe(r => {
        if (r instanceof Array && r.length > 0) {
          this.orgBussinessTypes = r;
        } else {
          this.orgBussinessTypes = null;
        }
      }),
      this.auth.getCountryList().subscribe(q => {
        if (q instanceof Array && q.length > 0) {
          this.countryList = q;
        } else {
          this.countryList = null;
        }
      })]
    );
  }

  getServiceProvider(value) {
    if (this.registrationForm.get('bizType').value == 'SERVICE_PROVIDER') {
      this.registrationForm.get('serviceTypeCode').setValidators(Validators.required);
      this.registrationForm.get('serviceTypeCode').markAsUntouched();
      this.registrationForm.get('serviceTypeCode').updateValueAndValidity();
      this.auth.getServiceType().subscribe((res) => {
        if (res instanceof Array && res.length > 0) {
          this.serviceTypeList = res;
        } else {
          this.serviceTypeList = null;
        }
      });
    } else {
      this.registrationForm.get('serviceTypeCode').setValue(null);
      this.registrationForm.get('serviceTypeCode').markAsUntouched();
      this.registrationForm.get('serviceTypeCode').setValidators(null);
      this.registrationForm.get('serviceTypeCode').updateValueAndValidity();
    }

  }

  loadStatesListByCountryCode(code: string) {
    this.auth.getStatesListByCountryCode(code).subscribe(
      (r) => {
        if (r instanceof Array && r.length > 0) {
          this.statesList = r;
        } else {
          this.statesList = null;
        }
      });
  }

  onNameChange(v) {
    if (v) {
      this.auth.isOrgnizationNameExist(v).subscribe(
        (r) => {
          if (r) {
            this.registrationForm.get('name').setErrors({ 'nameExist': true });
          }
        });
    }
  }
  onUserEmailChange(v) {
    if (v) {
      this.auth.isBusinessEmailExist(v).subscribe(
        (r) => {
          if (r) {
            this.registrationForm.get('useremail').setErrors({ 'emailExist': true });
          } else {
            this.registrationForm.get('useremail').setErrors(null);
            this.registrationForm.get('useremail').setValidators([Validators.required, Validators.pattern(this.auth.emailPattern)]);
            this.registrationForm.get('useremail').updateValueAndValidity();
          }
        });
    }
  }
  onBusinessEmailChange(v) {
    if (v) {
      this.auth.isBusinessEmailExist(v).subscribe(
        (r) => {
          if (r) {
            this.registrationForm.get('email').setErrors({ 'bizEmailExist': true });
          } else {
            this.registrationForm.get('email').setErrors(null);
            this.registrationForm.get('email').setValidators(Validators.pattern(this.auth.emailPattern));
            this.registrationForm.get('email').updateValueAndValidity();
          }
        });
    }
  }

  create() {
    this.isCreate = true;
    this.setrequestJSON().then(res => {
      this.auth.postCompany(this.requestJSON).subscribe((data) => {
        if (data) {
          //console.log(data);
          this.stepper.linear = false;
          this.stepstatus.steptwo = true;
          this.stepper.selectedIndex = 1;
          this.editable = false;
          this.stepper.linear = true;
        }
      }, (err) => {
        this.isCreate = false;
        this.stepstatus.steptwo = false;
      });
    })
  }

  cancel(): void {
    this.dialogRef.close();
  }

  setrequestJSON() {
    return new Promise((resolve, reject) => {
      let f = this.allApps.filter(t => t.isSelected);
      let code: any[] = [];
      f.forEach(element => {
        code.push({ "applicationCode": element.name.toUpperCase() });
      });
      this.requestJSON = {
        "organization": {
          "clientCode": "",
          "url": this.registrationForm.get('url').value,
          "name": this.registrationForm.get('name').value,
          "statusCode": true,
          "activeDate": new Date().getTime(),
          "bizType": this.registrationForm.get('bizType').value,
          "serviceTypeCode": this.registrationForm.get('serviceTypeCode').value,
          "email": this.registrationForm.get('email').value,
          "clientApplications": code,
          "defaultAddress": {
            "address": this.registrationForm.get('address').value,
            "address2": this.registrationForm.get('address2').value,
            "city": this.registrationForm.get('city').value,
            "stateCode": this.registrationForm.get('stateCode').value,
            "countryCode": this.registrationForm.get('countryCode').value,
            "zipCode": this.registrationForm.get('zipCode').value
          }
        },
        "user": {
          "fname": this.registrationForm.get('fname').value,
          "lname": this.registrationForm.get('lname').value,
          "phone": this.registrationForm.get('phone').value,
          "email": this.registrationForm.get('useremail').value,
          // "password": btoa(this.registrationForm.get('password').value),
          "countryCode": this.registrationForm.get('usercountryCode').value,
          "termAndCondition": this.registrationForm.get('termAndCondition').value
        }
      };
      resolve(true);
    });
  }


  // areMatch() {
  //   let confirmPass = this.registrationForm.get('confirmPassword').value;
  //   let pass = this.registrationForm.get('password').value;
  //   // if (this.registrationForm.get('confirmPassword').touched) {
  //   if (confirmPass && pass) {
  //     if (confirmPass != pass) {
  //       this.registrationForm.get('confirmPassword').setErrors({ 'isPasswordNotMatch': true });
  //     } else {
  //       this.registrationForm.get('confirmPassword').setErrors(null);
  //     }
  //   } else {
  //     this.registrationForm.get('confirmPassword').setErrors(null);
  //     this.registrationForm.get('confirmPassword').setValidators(Validators.required);
  //     this.registrationForm.get('confirmPassword').updateValueAndValidity();
  //   }
  //   // }
  // }

  public selectionChange($event?: StepperSelectionEvent): void {
    if ($event.selectedIndex === 1) {
      if (this.allApps.filter(t => t.isSelected).length > 0) {
        this.selectedIndex = $event.selectedIndex;
      } else {
        this.selectedIndex = $event.previouslySelectedIndex;
        this.stepper.selectedIndex = $event.previouslySelectedIndex;
        // return;
      }
    } else {
      this.selectedIndex = $event.selectedIndex;
    }


  }

  appSelectionSubmit(stepper: MatStepper) {
    //console.log(stepper);
    let f = this.allApps.filter(t => t.isSelected);
    if (f.length > 0) {
      this.selectedIndex = 1;
    } else {
      this.selectedIndex = 0;
    }
  }

}
