<div>
    <a href="javascript:void(0);" class="close-button" (click)="dismissAllMessages()">
        <mat-icon>close</mat-icon>
    </a>
    <h2 align="center" mat-dialog-title class="heading">{{ 'LABEL.NOTIFICATIONS' | translate}}</h2>
    <div mat-dialog-content class="container">
        <div class="timeline-item" *ngFor="let i of messageList; let ind=index">
            <mat-card class="message-container">                
                <div class="message-body">
                    <div [innerHTML]="i.messageBody | safeHtml"></div>
                    <!-- <h3 id="historyTitle{{ind}}" class="heading"> {{i.messageBody}}</h3> -->
                </div> 
                <div class="icon-container"><span class="icon-warning icon-style-warning"></span></div>             
            </mat-card>
        </div>
    </div>
    <div mat-dialog-actions align="center">
        <button mat-button class="btn btnDismissAll" (click)="dismissAllMessages()"> {{ 'BUTTON.DISMISS_ALL' | translate}} </button>
    </div>
</div>