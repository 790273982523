<div class="content-container">
  <!-- <div class="language-selector"></div> -->
  <div class="video-container" #bgvideo></div>
  <div class="logout-container">
    <mat-card class="floating-logout-card" #logincard>
      <div class="circle-placeholder">
        <div class="logo-circle">
          <img src="assets/images/procurant_logo.png" alt="Procurant" />
        </div>
      </div>
      <mat-card-content>
        <h2>Logout Successful!</h2>
        <caption><a [routerLink]="'/'">Click here</a> to login to Procurant again</caption>
      </mat-card-content>
      
    </mat-card>
  </div>
</div>