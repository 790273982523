<div mat-dialog-content>
    <div class="main-content">
        <div *ngIf="data?.icon=='block'" class="left-container"><span class="icon-block icon-style-block"></span></div>
        <div *ngIf="data?.icon=='warning'" class="left-container"><span class="icon-warning icon-style-warning"></span>
        </div>
        <div *ngIf="data?.icon=='mandatory'" class="left-container"><span
                class="icon-minus-solid icon-style-mandatory"></span>
        </div>

        <div class="right-container">
            <span id="contentData" class="contentData" [innerHTML]="data.message"> </span>
        </div>
    </div>
</div>
<div mat-dialog-actions class="action">
    <button mat-flat-button type="button" class="btn btnBasic mr-10" *ngIf="!data.hideCancel"
        (click)="onCancelClick()">{{data.buttonCancelText}}</button>
    <button mat-flat-button type="button" class="btnPrimary" (click)="onOkClick()"
        cdkFocusInitial>{{data.buttonOkText}}</button>
</div>