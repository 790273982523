import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { InitLoaderService } from './interceptor/initloader.service';
import { OneAuthService } from 'one-auth';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  isAuthenticated: boolean = false;
  
  constructor(public translate: TranslateService,private init: InitLoaderService,  private auth: OneAuthService, private route: Router) {
    // 'ProcurantOne Single Sign-On';
    // this.auth.updateDatafromStorage().then(d => {
    //   this.auth.getTokenFromStorage().subscribe(t => {
    //     if (t !== undefined && t != null) {
    //       this.auth.isUserValid().subscribe((s) => {
    //         this.isAuthenticated = true;
    //         console.log(this.auth.user);
    //         this.translate.use(this.auth.user.info.locale);
    //         if (s) {
    //           //this.navigateToMain();
    //         } else {
    //           this.navigateToLogin();
    //         }
    //       }, (err) => {
    //         console.log('error authenticating..');
    //         this.navigateToLogin();
    //       });
    //     }
    //   });
    // }, ((reject) => {
    //   console.log('no data in storage available ..navigating to login to re-authenticate');
    //   this.navigateToLogin();
    // })

    // ).catch((err) => {
    //   console.log("error! Handle this error");
    // });
    this.init.load();
    
  }

  ngOnInit() {
    // var lang = this.translate.getBrowserLang();
    this.translate.setDefaultLang('en_US');
    this.translate.use('en_US').subscribe(()=>{
      document.title = this.translate.instant('LOGIN.DOCUMENT_TITLE');
    });
    
  }

  navigateToMain() {
    this.route.navigateByUrl("dashboard");
  }

  navigateToLogin() {
    this.route.navigateByUrl("login");

  }



}
