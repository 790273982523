import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { OneLoginComponent } from './components/one-login/one-login.component';
// import { OneAuthService } from './services/one-auth.service';
import { MaterialUIModule } from '../material-ui/material-ui.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppSelectorComponent } from './components/app-selector/app-selector.component';
import { CompanyRegistrationComponent } from './components/company-registration/company-registration.component';
import { OneLogoutComponent } from './components/one-logout/one-logout.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { OneAuthModule } from 'one-auth';
import { AppToolbarsModule } from '../app-toolbars/app-toolbars.module';
import { ActivateUserComponent } from './components/activate-user/activate-user.component';
import { OneAuthenticateComponent } from './components/one-authenticate/one-authenticate.component';
import { AdminMessagesOverlayComponent, SafeHtmlPipe } from './components/admin-messages-overlay/admin-messages-overlay.component';
import { ConnectRegistrationComponent } from './components/connect-registration/connect-registration.component';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
// import { OneToolbarModule } from 'one-auth';
// import { OneToolbarsModule } from 'one-auth';


@NgModule({
    declarations: [
        OneLoginComponent,
        AppSelectorComponent,
        CompanyRegistrationComponent,
        OneLogoutComponent,
        ForgotPasswordComponent,
        ActivateUserComponent,
        OneAuthenticateComponent,
        AdminMessagesOverlayComponent,
        ConnectRegistrationComponent,
        SafeHtmlPipe
    ],
    imports: [
        CommonModule,
        MaterialUIModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        OneAuthModule,
        AppToolbarsModule,
        SharedComponentsModule
    ],
    providers: [],
    exports: []
})
export class OneLoginModule { }
