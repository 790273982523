import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { OneAuthService, OneNotifyService } from 'one-auth';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';




@Component({
  selector: 'app-activate-user',
  templateUrl: './activate-user.component.html',
  styleUrls: ['./activate-user.component.scss']
})
export class ActivateUserComponent implements OnInit {
  user: any;
  isPasswordMatch: boolean;
  token: any;
  userData: any;
  showResetPasswordPage = false;

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(public auth: OneAuthService, public notify: OneNotifyService, private snackBar: MatSnackBar, private _Activatedroute: ActivatedRoute, private fb: FormBuilder, private router: Router, private translate: TranslateService) {
    let route = this.router.routerState.snapshot.url.split('/');
    let page = route[route.length - 1];
    if (page.includes("reset-password")) {
      this.showResetPasswordPage = true;
    }
    this.createForm();
  }

  createForm() {
    this.user = this.fb.group({
      fname: [''],
      lname: [''],
      countryCode: [''],
      phone: [''],
      email: [''],
      password: ['', Validators.compose([
        Validators.minLength(8),
        Validators.required,
        Validators.pattern(this.auth.passwordPattern)
      ])],
      confirmPassword: ['', Validators.required],
      termAndCondition: [''],
      token: [''],
    });
  }



  showSnackBarMessage(message: string, type: string) {
    // this.snackBar.open(message, '', {
    //   duration: time || 4000, horizontalPosition: this.horizontalPosition,
    //   verticalPosition: this.verticalPosition
    // });
    this.notify.show(message, type);
  }

  ngOnInit() {
    this.userData = { "fname": "", "email": "" }
    this._Activatedroute.queryParams.subscribe(params => {
      this.token = params["token"];
      this.getUserByToken();
    });
  }

  getUserByToken() {
    this.auth.getUserByToken(this.token).subscribe(
      (res) => {
        this.userData = res;
        if (!this.userData) {
          this.showSnackBarMessage(this.translate.instant("LOGIN.MESSAGE.PASSWORD_LINK_ERROR"), 'success');
          this.redirectToLogin();
        } else {
          this.user.controls['email'].setValue(this.userData.email);
          this.user.controls['token'].setValue(this.token);
        }
      },
      error => {
        //console.log(error, error.status);
        if (error.status === 400) {        
          if (error.headers.get('Error').includes("Password link is")) {
            this.showSnackBarMessage(this.translate.instant("LOGIN.MESSAGE.PASSWORD_RESET_LINK_EXPIRE_ERROR"), 'error');
          } else {
            this.showSnackBarMessage(error.headers.get('Error'), 'error');
          }
          this.redirectToLogin();
        }
      });
  }

  redirectToLogin() {
    this.router.navigate(['/']);
  }

  areMatch() {
    let confirmPass = this.user.get('confirmPassword').value;
    let pass = this.user.get('password').value;
    if (confirmPass && pass) {
      if (confirmPass != pass) {
        this.user.get('confirmPassword').setErrors({ 'isPasswordNotMatch': true });
      } else {
        this.user.get('confirmPassword').setErrors(null);
      }
    } else {
      this.user.get('confirmPassword').setErrors(null);
      this.user.get('confirmPassword').setValidators(Validators.required);
      this.user.get('confirmPassword').updateValueAndValidity();
    }
  }

  create() {
    this.userData.password = btoa(this.user.get('password').value);
    let resData = { user: this.userData, token: this.token }
    this.auth.updateUserPassword(resData).subscribe(
      (data) => {
        if (data) {
          this.showSnackBarMessage(this.translate.instant("LOGIN.MESSAGE.PASSWORD_SUCCESS"), 'success');
          this.redirectToLogin();
        } else {
          this.showSnackBarMessage(this.translate.instant("LOGIN.MESSAGE.PASSWORD_LINK_ERROR"), 'error');
          this.redirectToLogin();
        }
      }, error => {
        this.showSnackBarMessage(error, 'error');
      });
  }

  resetUser() {
    this.user.get('password').reset();
    this.user.get('confirmPassword').reset();
  }



}
