import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogBoxService {
  dialogRef: MatDialogRef<any>;
  data: any;
  isSubmited: boolean = false;

  constructor(public dialog: MatDialog) { }

  // Confirmation dialog box
  openConfirmDialog(message, buttonOkText, buttonCancelText, width?, height?, hideCancelBtn = false, title = '', icon = '') {
    return new Promise((resolve, reject) => {
      buttonOkText = buttonOkText || 'BUTTON.YES';
      buttonCancelText = buttonCancelText || 'BUTTON.NO';
      let dialogWidth: string = '250px';
      let dialogHeight: string = '250px';

      if (width)
        dialogWidth = width.toString() + 'px';
      if (height)
        dialogHeight = height.toString() + 'px';

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: dialogWidth,
        height: dialogHeight,
        data: { message: message, buttonOkText: buttonOkText, buttonCancelText: buttonCancelText, hideCancel: hideCancelBtn, title: title, icon: icon },
        disableClose: true //can not close dialog on escape button
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result.result === true) {
            resolve(true);
          }
          else { reject(); }
        }
        else { reject(); }
      });
    });
  }

  closeDialog(cloaseData?) {
    this.dialogRef.close(cloaseData);
  }

  submited() {
    this.isSubmited = true;
  }
}
