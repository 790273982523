<side-nav>
    <!-- <div sidenavitem>
        <a mat-list-item routerLink="docs/dashboard" routerLinkActive="active" state="{data: {'linktitle': 'Dashboard')}">
            <mat-icon>dashboard</mat-icon>
            {{ 'TRUSTDOCS.DASHBOARD' | translate }} 
        </a>
        <a mat-list-item routerLink="docs/shared" routerLinkActive="active">
            <mat-icon>folder_shared</mat-icon>
            {{ 'TRUSTDOCS.ORG_DOCS' | translate }}
        </a>
        <a mat-list-item routerLink="docs/received" routerLinkActive="active">
            <mat-icon>move_to_inbox</mat-icon>
            {{ 'TRUSTDOCS.RECEIVED' | translate }}
        </a>
    </div> -->
</side-nav>