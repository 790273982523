<div class="content-container">
  <!-- <div class="language-selector"></div> -->
  <!-- <div class="video-container" #bgvideo></div> -->
  <div class="login-container" [ngClass]="(!showLogin)?'blur-out':'blur-in'" *ngIf="showLogin; else showProccessing">
    <mat-card class="floating-login-card" #logincard>
      <div class="message-box">
        <div *ngIf="successMsg != ''" class="message-text-success">{{successMsg}}</div>
        <div *ngIf="warnMsg != ''" class="message-text-warn">{{warnMsg}}</div>
        <div *ngIf="errorMsg !=''" class="message-text-error">{{errorMsg}}</div>
        <div *ngIf="loginForm.controls['username'].touched && loginForm.controls['username'].hasError('required')"
          class="message-text-error">
          {{ 'LOGIN.MESSAGE.USER_NAME_ERROR' | translate }}
        </div>
        <div *ngIf="loginForm.controls['password'].touched && loginForm.controls['password'].hasError('required')"
          class="message-text-error">
          {{ 'LOGIN.MESSAGE.PASSWORD_ERROR' | translate }}
        </div>
        <div *ngIf="loginForm.controls['username'].hasError()" class="message-text-error">
          {{errorMsg}}
        </div>
        <!-- <div *ngIf="loginForm.controls['username'].hasError('invalidUser')" class="message-text-error">
           {{ 'LOGIN.MESSAGE.USER_NAME_ERROR' | translate }} -->
        <!-- {{errorMsg}} -->
        <!-- <div *ngIf="userValidationloginCount == maxAttempt; else invalidLoginUser "> 
            Your account has been locked. To Unlock, please use Forgot Password link to reset your password.
          </div>
          <ng-template #invalidLoginUser>
             Invalid username
          </ng-template> -->
        <!-- </div> -->
        <div *ngIf="loginForm.controls['password'].hasError()" class="message-text-error">
          {{errorMsg}}
        </div>
        <!-- <div *ngIf="loginForm.controls['password'].hasError('invalidPassword')" class="message-text-error">
           {{ 'LOGIN.MESSAGE.USER_NAME_ERROR' | translate }} -->
        <!-- {{errorMsg}} -->
        <!-- <div *ngIf="loginCount < maxAttempt; else forgotPassword "> 
            Invalid Password, You Have only {{maxAttempt - loginCount }} attempt.
          </div>
          <ng-template #forgotPassword>
            Your account has been locked. To Unlock, please use Forgot Password link to reset your password.
          </ng-template>
        </div> -->
      </div>
      <div class="circle-placeholder">
        <div class="logo-circle">
          <img src="assets/images/procurant_logo.png" alt="Procurant" />
        </div>
      </div>
      <div class="login-header">
        <!-- <h1>{{ 'LOGIN.SIGN_IN_TO_PROCURANT' | translate }}</h1> -->
        <h1>{{ 'LOGIN.PROCURANT' | translate }}</h1>
        <p>{{ 'LOGIN.PROCURANT_DETAILS' | translate }}</p>
      </div>
      <!-- (ngSubmit)="login()" -->
      <form [formGroup]="loginForm">
        <mat-form-field floatLabel="always" appearance="outline" *ngIf="buttonToShow == 'CONTINUE'">
          <!-- <mat-label>{{ 'LOGIN.USER_NAME' | translate }}</mat-label> -->
          <input matInput placeholder="{{ 'LOGIN.USER_NAME' | translate }}" [readonly]="buttonToShow != 'CONTINUE'"
            formControlName="username" (input)="validateForm()" class="pass-field">
          <span matPrefix>
            <mat-icon>perm_identity</mat-icon>
          </span>
          <!-- <mat-error
            *ngIf="loginForm.controls['username'].touched || loginForm.controls['username'].hasError('required')">
            {{ 'LOGIN.MESSAGE.USER_NAME_ERROR' | translate }}
          </mat-error> -->
        </mat-form-field>
        <div class="user-name-text-box" *ngIf="buttonToShow != 'CONTINUE'">
          <span class="user-name-text">
            <mat-icon>perm_identity</mat-icon>{{loginForm.controls['username'].value}}
          </span>
          <span class="user-name-text">
            <mat-icon class="edit-icon" (click)="backToContinue()">edit</mat-icon>
          </span>
        </div>
        <ng-container *ngIf="buttonToShow == 'LOGINWITHPROCURANT'">
          <mat-form-field floatLabel="always" appearance="outline" (input)="validateForm()">
            <!-- <mat-label>{{ 'LOGIN.PASSWORD' | translate }}</mat-label> -->
            <input matInput placeholder="{{ 'LOGIN.PASSWORD' | translate }}" [readOnly]="loginCount==maxAttempt"
              type="password" formControlName="password">
            <span matPrefix>
              <mat-icon>lock</mat-icon>
            </span>
            <!-- <mat-error
            *ngIf="loginForm.controls['password'].touched && loginForm.controls['password'].hasError('required')">
            {{ 'LOGIN.MESSAGE.PASSWORD_ERROR' | translate }}
          </mat-error> -->
          </mat-form-field>
        </ng-container>
        <!-- <div class="login-error">
          <mat-error *ngIf="loginError">
            {{ errorMsg }}
          </mat-error>
        </div> -->
        <div class="login-btn">
          <!-- [disabled]="inValid" -->
          <!-- <ng-container *ngIf="buttonToShow == 'CONTINUE'">
          <button mat-raised-button class="btnPrimary signupbtn" (click)="checkUser()" >Continue
            <mat-icon>keyboard_arrow_right</mat-icon>
          </button>
          </ng-container>
          <ng-container *ngIf="buttonToShow == 'LOGINWITHPROCURANT'">
            <button mat-raised-button class="btnPrimary signupbtn" (click)="login()" [disabled]="inValid">Login With Procurant
              <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
          </ng-container>
          <ng-container *ngIf="buttonToShow == 'LOGINWITHSSOPROVIDER'">
            <button mat-raised-button class="btnPrimary signupbtn" (click)="loginWithSsoProvider()" [disabled]="inValid">Login With Microsoft
              <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
          </ng-container> -->
          <!-- <ng-container *ngTemplateOutlet="buttonToShow"></ng-container> -->
          <ng-container [ngTemplateOutlet]="this[buttonToShow]"></ng-container>
        </div>
      </form>
      <div class="sublinks">

        <div class="signup">
          <a href="https://www.procurant.com/supplier-registration">{{'LOGIN.JOIN_US' | translate }}</a>
        </div>

        <div class="signup">
          <a (click)="openConnectRegistrationDialog()">{{'LOGIN.JOIN_CONNECT' | translate }}</a>
        </div>

        <div class="forgot-password" *ngIf="buttonToShow != 'LOGINWITHSSOPROVIDER'">
          <a [routerLink]="'/forgot-password'">{{'LOGIN.FORGET_PASSWORD' | translate }}</a>
        </div>
        <!-- <div>
          <button mat-raised-button class="btn btnBasic" (click)="openRegistrationDialog()">{{
            'LOGIN.JOIN_US' | translate }}</button>
        </div> -->
        <!-- <div> -->
        <!--Removed singup-->
        <!-- </div> -->
      </div>
    </mat-card>
  </div>
  <ng-template #showProccessing>
    <div class="waitlogo">
      <div class="circle-placeholder" [ngClass]="(showWaitLogo)?'blur-in':'blur-out'">
        <div class="logo-circle">
          <img src="assets/images/procurant_logo.png" alt="Procurant" />
        </div>

      </div>
      <div class="company" [ngClass]="(showWaitLogo)?'text-trackin blur-in':'text-trackout blur-out'">
        <h1>Procurant</h1>
      </div>
    </div>
  </ng-template>
  <ng-template #CONTINUE>
    <button mat-raised-button class="btnPrimary signupbtn" (click)="checkUser()">Continue
      <!-- <mat-icon>keyboard_arrow_right</mat-icon> -->
    </button>
  </ng-template>
  <ng-template #LOGINWITHPROCURANT>
    <button mat-raised-button class="btnPrimary signupbtn" (click)="login()" [disabled]="inValid">
      <img src="assets/images/procurant_logo.png" alt="Procurant" />
      Login With Procurant
      <!-- {{ 'LOGIN.SIGN_IN' | translate }} -->
      <!-- <mat-icon>keyboard_arrow_right</mat-icon> -->
    </button>
  </ng-template>
  <ng-template #LOGINWITHSSOPROVIDER>
    <!-- [disabled]="inValid" -->
    <button mat-raised-button class="btnPrimary signupbtn" (click)="loginWithSsoProvider('MICROSOFT')" *ngIf="ssoDetails?.ssoProviderCode=='MICROSOFT'">
      <img src="assets/images/Microsoft.png" alt="Procurant" />
      Login With Microsoft
      <!-- {{ 'LOGIN.SIGN_IN' | translate }} -->
      <!-- <mat-icon>keyboard_arrow_right</mat-icon> -->
    </button>
    <button mat-raised-button class="btnPrimary signupbtn" (click)="loginWithSsoProvider('OKTA')" *ngIf="ssoDetails?.ssoProviderCode=='OKTA'">
      <img src="../../../../../assets/images/Okta.png" alt="Procurant" />
      Login With Okta
      <!-- {{ 'LOGIN.SIGN_IN' | translate }} -->
      <!-- <mat-icon>keyboard_arrow_right</mat-icon> -->
    </button>
  </ng-template>
</div>