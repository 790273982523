import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpRequestInterceptor } from './interceptor/http-interceptor.service';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MaterialUIModule } from './modules/material-ui/material-ui.module';
import { OneLoginModule } from './modules/one-login/one-login.module';
import { OneAuthModule, OneAuthService, OneToolbarModule, OneNotifyService, OneTrackerModule, OneTrackerService } from 'one-auth';
import { environment } from '../environments/environment';
import { InitLoaderService } from './interceptor/initloader.service';
import { SharedComponentsModule } from './modules/shared-components/shared-components.module';
// import { OneToolbarModule } from 'one-auth/one-auth';




@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    OneAuthModule.forRoot(environment),
    OneTrackerModule.forRoot(environment),
    HttpClientModule,
    MaterialUIModule,
    OneLoginModule,
    OneToolbarModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedComponentsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [   
    OneAuthService,
    OneTrackerService,
    OneNotifyService,
    InitLoaderService,
    [{
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true
    }],
    TranslateService
  ],
  bootstrap: [
    AppComponent
  ],
  exports: [

  ]
})

export class AppModule { }
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}