import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
// import { OneAuthService } from '../../services/one-auth.service';
// import { IMAGES } from '../../../../../environment/config.all';
import { URLS } from '../../../../../environments/config.url';
import { IMAGES } from '../../../../../environments/config.all';
import { OneAuthService } from 'one-auth';
import { MatDialog } from '@angular/material/dialog';
import { AdminMessagesOverlayComponent } from '../admin-messages-overlay/admin-messages-overlay.component';
import { environment } from '@procurant/env/environment';


@Component({
  selector: 'app-selector',
  templateUrl: './app-selector.component.html',
  styleUrls: ['./app-selector.component.scss']
})
export class AppSelectorComponent implements OnInit {
  public IMAGES = IMAGES;
  //
  public dashhash: string;
  public userApps: Array<Object>;
  adminMessages = [];
  @ViewChild('ascontainer') public el: ElementRef;
  constructor(public auth: OneAuthService, public router: Router, private _Activatedroute: ActivatedRoute, public matDialog: MatDialog) { }

  ngOnInit() {
    this.userApps = [];
    this.auth.checkLoginStatus().then((r) => {
      this.auth.checkAdminPermission(this.auth.user.subApps).then(r => {
        //console.log("subapps", this.auth.user.subApps)
        //support implementation for BUYER,SUPPLIER, and ADMIN app in case internalAdmin is true
        if (this.auth.user.info.internalAdmin) {          
          this.userApps = this.auth.user.subApps.filter(t => t.applicationCode == 'BUYER' || t.applicationCode == 'SUPPLIER' || t.applicationCode == 'ADMIN' || t.applicationCode == 'CARRIER');        
        }
        else {         
          this.userApps = this.auth.user.subApps;
        }
      })

      let showMessageflag: boolean = this._Activatedroute.snapshot.params['flag'];
      if (showMessageflag) {
        this.getAdminMessages();
      }
    }, err => {
      this.router.navigate(['/']);
    });
  }

  getAdminMessages() {
    this.auth.getAdminMessageList().subscribe(res => {
      this.adminMessages = res;
      if (this.adminMessages && this.adminMessages.length > 0) {
        this.matDialog.open(AdminMessagesOverlayComponent, {
          width: '50%',
          data: this.adminMessages,
          disableClose: true,
          autoFocus: false
        });
      }
    });
  }

  ngAfterViewInit() {
    var wait = setTimeout(() => {
      this.el.nativeElement.classList.add('bgblur');
    }, 100);
  }



  checkSingleAppAccess() {
    //console.log(this.auth.user.subApps);
    if (this.auth.user.subApps.length <= 1) {
      window.location.href = this.auth.user.subApps[0].appLink;
    }
  }

  setAppLink(appName): string {
    switch (appName) {
      case "BUYER":
        return environment.SUB_DOMAIN + 'buyer' + environment.DOMAIN;
      case "SUPPLIER":
        return environment.SUB_DOMAIN + 'vendor' + environment.DOMAIN;
      case "CARRIER":
        return environment.SUB_DOMAIN + 'carrier' + environment.DOMAIN;
      case "SHARE":
        return environment.SUB_DOMAIN + 'share' + environment.DOMAIN;
      case "CONNECT":
        return environment.SUB_DOMAIN + 'link' + environment.DOMAIN;
      case "START":
        return environment.SUB_DOMAIN + 'connect' + environment.DOMAIN;
      case "PTI":
        return environment.SUB_DOMAIN + 'trace' + environment.DOMAIN;
      case "INSPECT":
        return environment.SUB_DOMAIN + 'inspect' + environment.DOMAIN;
      default:
        return '';
    }
  }

  selectedApp(app) {
    // if (app.applicationCode != "START") { 
    let templink = this.setAppLink(app.applicationCode);
    //console.log("app.appLink", app.appLink);
    //console.log(templink);
    this.auth.currentActiveApp(app.name);
    if (app.appLink == templink) {
      //this.router.navigate(['dashboard']);
      window.location.href = app.appLink;
    } else {
      window.location.href = app.appLink;
    }
    //this.auth.currentActiveApp(app.applicationCode);
    // }
  }

}
