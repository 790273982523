import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { OneAuthService, OneNotifyService } from 'one-auth';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  user: any;
  userData: any;
  emailSentMsg:string;
  // showEmailConformPage = false;
  public showLogin: boolean = false;
  public showWaitLogo: boolean = true;
  pageToShow = "EMAIL_PAGE";

  constructor(public auth: OneAuthService, public notify: OneNotifyService, public dialog: MatDialog, private router: Router, private fb: FormBuilder, public translate: TranslateService) { }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.showLogin = true;
    this.showWaitLogo = false;
    this.user = this.fb.group({
      userName: ['', Validators.compose([
        Validators.required,
        Validators.pattern(this.auth.emailPattern)
      ])],
    });
  }

  create() {
    let i = this.user.get('userName').value;
    let r =
    {
      "user": {
        "fname": null,
        "email": i,
        "password": null
      },
      "token": null,
      "tokenStatus": null
    };
    this.auth.forgotPassword(r).subscribe(
      (data) => {
        if (data) {
          // this.showEmailConformPage = true;
          this.emailSentMsg=data.successMessage;
          this.pageToShow = "EMAIL_CONFIRM_PAGE";
        } else {
        }
      },
      error => {
        if (error.status === 400) {
          this.user.get('userName').setErrors({ 'invalidUser': true });
        }
      });
  }

  checkUser(){
      let i = this.user.get('userName').value;
      this.auth.checkUserForgotPassword(i).subscribe(res => {
        if(res){
          this.pageToShow = "SSO_USER_PAGE";
        }else{
          this.create();
        }
        }, (err) => {
          if (err.status === 400) {
            this.user.get('userName').setErrors({ 'invalidUser': true });
          }
        });
  }

  done() {
    this.router.navigate(['/']);
  }

}
