import { Component, OnInit } from '@angular/core';
import { OneAuthService } from 'one-auth';

@Component({
  selector: 'app-one-logout',
  templateUrl: './one-logout.component.html',
  styleUrls: ['./one-logout.component.scss']
})
export class OneLogoutComponent implements OnInit {

  constructor(private auth: OneAuthService) { }

  ngOnInit() {
    this.auth.clearSession();
  }

}
