<app-toolbar>
  <div logo><img src="{{IMAGES.PROCURANT_LOGO}}"></div>
  <div product-name>
   
  </div>
  <span userimage>
    <img *ngIf="auth?.thumbnil" [src]="auth?.thumbnil" router="UserProfileModule" alt="" title="" class="img">
    <div *ngIf="!auth?.thumbnil" class="img-avtar">
      <span>{{avtarName}}</span>
    </div>
  </span>

  <!-- double column -->
  <div username class="double-user-profile">
    <div>
      <img *ngIf="auth?.thumbnil" [src]="auth?.thumbnil" router="UserProfileModule" alt="" title="" class="img">
      <div *ngIf="!auth?.thumbnil" class="img-avtar">
        <span>{{avtarName}}</span>
      </div>
    </div>
    <div>
      <span class="user-name">
        {{user?.info?.firstName}} {{user?.info?.lastName}}
      </span>
    </div>
  </div>
  <div menu-options>
    <button mat-menu-item (click)="logout()">
      {{ 'BUTTON.LOGOUT' | translate }}
    </button>
  </div>
</app-toolbar>