<div [formGroup]="registrationForm">

    <div class="registration-dialog">
        <div class="dialog-header">
            <div fxLayout="row" style="align-self: flex-end">
                <a href="javascript:void(0);" (click)="onCancelClick()">
                    <mat-icon>close</mat-icon>
                </a>
            </div>
            <h2 mat-dailog-title class="logoprimary">Procurant<span class="logosecondary">Connect</span>
                {{ 'LOGIN.REGISTRATION' | translate }}</h2>

            <!-- <caption>{{ 'LOGIN.PROCURANT_DETAILS' | translate }}</caption> -->
        </div>
        <mat-dialog-content>
            <form class="mat-dialog-content">
                <ng-template [ngIf]="!isVertical" [ngIfElse]="verticalStepper" #horizontalStepper>
                    <mat-horizontal-stepper #stepper linear class="horizontalStepper"
                        [selectedIndex]="this.selectedIndex" (selectionChange)="selectionChange($event)">
                        <mat-step label="{{ 'LOGIN.COMPANY_DETAILS' | translate }}" [completed]="stepstatus.steptwo"
                            [editable]="editable">
                            <ng-container *ngTemplateOutlet="companyDetails"></ng-container>
                        </mat-step>
                        <mat-step label="{{ 'LOGIN.CONFIRMATION' | translate }}">
                            <ng-container *ngTemplateOutlet="finalizeSub"></ng-container>
                        </mat-step>
                    </mat-horizontal-stepper>
                </ng-template>

                <ng-template #verticalStepper>
                    <mat-vertical-stepper #stepper linear class="verticalStepper">
                        <!--   <mat-step label="{{ 'LOGIN.CHOOSE_APPLICATION' | translate }}" [completed]="stepstatus.stepone" [editable]="editable">
                        <ng-container *ngTemplateOutlet="chooseSub"></ng-container>
                      </mat-step> -->
                        <mat-step label="{{ 'LOGIN.COMPANY_DETAILS' | translate }}" [completed]="stepstatus.steptwo"
                            [editable]="editable">
                            <ng-container *ngTemplateOutlet="companyDetails"></ng-container>
                        </mat-step>
                        <mat-step label="{{ 'LOGIN.CONFIRMATION' | translate }}">
                            <ng-container *ngTemplateOutlet="finalizeSub"></ng-container>
                        </mat-step>
                    </mat-vertical-stepper>
                </ng-template>
            </form>
        </mat-dialog-content>
    </div>


    <ng-template #companyDetails matStepperIcon="edit">

        <div class="stepContent companyDetails">
            <div class="grp">
                <!-- <div class="grp-header">{{ 'COMPANY.LABEL.BUSINEES_DETAILS' | translate }}</div> -->
                <div class="grp-fields">
                    <mat-form-field>
                        <input matInput formControlName="email"
                            (blur)="onBusinessEmailChange(registrationForm.controls['email'].value)"
                            placeholder="{{ 'COMPANY.LABEL.BUSINNES_EMAIL' | translate }}">
                        <mat-error
                            *ngIf="registrationForm.controls['email'].touched && registrationForm.controls['email'].hasError('pattern')">
                            {{ 'COMPANY.MESSAGE.EMAIL_VALID_ERROR' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="grp">
                <div class="grp-fields details">
                    <mat-form-field>
                        <input matInput formControlName="fname" maxlength="64"
                            placeholder="{{ 'COMPANY.LABEL.FIRST_NAME' | translate }}">
                        <mat-error
                            *ngIf="registrationForm.controls['fname'].touched && registrationForm.controls['fname'].hasError('required')">
                            {{ 'COMPANY.MESSAGE.FIRST_NAME_ERROR' | translate }}
                        </mat-error>
                        <mat-error
                            *ngIf="registrationForm.controls['fname'].touched && registrationForm.controls['fname'].hasError('pattern')">
                            {{ 'COMPANY.MESSAGE.FIRST_NAME_VALID_ERROR' | translate }}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <input matInput formControlName="lname" maxlength="64"
                            placeholder="{{ 'COMPANY.LABEL.LAST_NAME' | translate }}">
                        <mat-error
                            *ngIf="registrationForm.controls['lname'].touched && registrationForm.controls['lname'].hasError('required')">
                            {{ 'COMPANY.MESSAGE.LAST_NAME_ERROR' | translate }}
                        </mat-error>
                        <mat-error
                            *ngIf="registrationForm.controls['lname'].touched && registrationForm.controls['lname'].hasError('pattern')">
                            {{ 'COMPANY.MESSAGE.LAST_NAME_VALID_ERROR' | translate }}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-select formControlName="countryCode"
                            placeholder="{{'COMPANY.LABEL.COUNTRY_CODE'|translate}}*" disableOptionCentering>
                            <mat-option *ngFor="let code of countryList" [value]="code.countryCode">
                                {{code.shortName}}
                                : {{code.callingCode}}</mat-option>
                        </mat-select>
                        <mat-error
                            *ngIf="registrationForm.controls['countryCode'].touched && registrationForm.controls['countryCode'].hasError('required')">
                            {{ 'COMPANY.MESSAGE.COUNTRY_CODE_ERROR' | translate }}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <input matInput formControlName="phone" maxlength="14"
                            placeholder="{{ 'COMPANY.LABEL.PHONE_NUMBER' | translate }}*">
                        <mat-error
                            *ngIf="registrationForm.controls['phone'].touched && registrationForm.controls['phone'].hasError('required')">
                            {{ 'COMPANY.MESSAGE.PHONE_NUMBER_REQUIRED_ERROR' | translate }}
                        </mat-error>
                        <mat-error
                            *ngIf="registrationForm.controls['phone'].touched && registrationForm.controls['phone'].hasError('pattern')">
                            {{ 'COMPANY.MESSAGE.PHONE_NUMBER_VALID_ERROR' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>

            </div>
        </div>
        <div class="terms">
            <!-- <div> -->
            <!-- <mat-checkbox formControlName="termAndCondition">
                {{ 'COMPANY.LABEL.TERMS_CONDITIONS1' | translate }} {{ 'COMPANY.LABEL.TERMS_CONDITIONS2' | translate }}
            </mat-checkbox>
            <mat-error
                *ngIf="registrationForm.controls['termAndCondition'].touched && registrationForm.controls['termAndCondition'].hasError('required')">
                {{ 'COMPANY.MESSAGE.TERM_CONDITION_ERROR' | translate }}
            </mat-error>
            <mat-error
                *ngIf="registrationForm.controls['termAndCondition'].touched && registrationForm.controls['termAndCondition'].hasError('pattern')">
                {{ 'COMPANY.MESSAGE.TERM_CONDITION_ERROR' | translate }}
            </mat-error> -->
            <!-- </div> -->
        </div>
        <div class="mat-stepper-buttons">
            <button mat-stroked-button type="button" class="regcancel btnSecondary" (click)="onCancelClick()">{{
                'COMPANY.LABEL.CANCEL' | translate }}</button>
            <button mat-flat-button type="button" class="btnPrimary" [disabled]="registrationForm.invalid || isCreate"
                (click)="create()">{{'COMPANY.LABEL.SUBMIT'
                |translate}}</button>
        </div>
    </ng-template>
    <ng-template #finalizeSub mat-StepperIcon="done">
        <div class="intro" style="font-size: 20px;">{{ 'COMPANY.LABEL.THANKS_FOR_REGISTERING' | translate }}
            {{ 'COMPANY.LABEL.WITH_US' | translate }}</div>
        <div class="stepContent companyDetails">
            <div><img src='assets/images/thank-you.png' /></div>
            <div style="width: 100%;text-align: center;margin: 25px 0 5px 0;font-size: 16px;">
                {{ 'COMPANY.LABEL.REGISTRATION_SUCCESS_MSG_1' | translate }}</div>
            <div>{{ 'COMPANY.LABEL.REGISTRATION_SUCCESS_MSG_2' | translate }}</div>
        </div>
    </ng-template>
</div>