import { Component, OnInit } from '@angular/core';
import { IMAGES } from '../../../../../environments/config.all';
// import { AppStorageService } from 'src/app/services/app-storage.service';
import { Router } from '@angular/router';
import { OneAuthService } from 'one-auth';
import { AppToolbarService } from '../../services/app-toolbar.service';
// import { AppToolbarComponent } from '../../../app-toolbar/app-toolbar/app-toolbar.component';

@Component({
  selector: 'header-toolbar',
  templateUrl: './header-toolbar.component.html',
  styleUrls: ['./header-toolbar.component.scss']
})
export class HeaderToolbarComponent implements OnInit {
  public IMAGES = IMAGES;
  public user;
  public subApps;
  localUrl: any;
  editIcon: any;
  avtarName: any;
  constructor(public auth: OneAuthService, private router: Router, public appToolbarService: AppToolbarService) { }

  ngOnInit() {
    this.user = this.auth.user;
    this.subApps = this.auth.user.subApps;
    this.setUserImage();
    // if(this.auth.user == undefined || this.auth.user.info.id === undefined){
    //   this.auth.loadAuthFromStorage().then(user => {
    //     this.auth.getTokenFromStorage().subscribe((token) => {
    //       this.auth.checkUserAuthValidity().subscribe((res) => {
    //         if (this.auth.user!== undefined) {
    //           console.log(this.auth.user);
    //           this.user = this.auth.user;
    //           //Everything checks out, has single app acess,redirecting directly to app to avoid flicker
    //           // window.location.href = this.auth.user.permissions[0].appLink;
    //         } else {
    //           // this.router.navigate(['apps']);
    //         }
    //         // clearTimeout(this.waiting);
    //       }, (err) => {

    //         console.log(err);
    //       });
    //     });
    //   });
    // } else{
    //   this.user = this.auth.user;
    //   console.log(this.user);
    // }
    // (RD)PMB-6554 New header and navigation menu bar for Procurant Commerce
    let name = this.user.info.firstName.split(" ")[0] +' '+this.user.info.lastName.split(" ")[0];
    this.avtarName = name.split(" ").map((n)=>n[0]).join("").toUpperCase();

  }

  setUserImage() {
    if (this.auth.user.info && this.auth.user.info.fileName != null && this.auth.user.info.objectId != null) {
      this.auth.getProfileImage(this.auth.user.info.objectId).subscribe(r => {
        if (r) {
          this.auth.thumbnil = null;
          this.auth.thumbnil = "data:image/" + this.auth.user.info.fileName.split('.').pop() + ";base64," + r
        }
      }, err => {
        if (err.status == 401) {
          this.logout();
        }
      });
    }
  }

  openAdmin() {
    //window.location.href = environment.BASE_API_URL + "common-app/#/app/company/account-setting"
  }

  logout() {
    this.auth.logoutUser().subscribe(res => {
      // sessionStorage.clear();
      if (res.status === 200) {
        this.auth.clearSession();
        this.router.navigate(['logout']);
      }
    });
  }

 


}
