<header-toolbar></header-toolbar>
<div class="app-selector-container">
  <div class="bg" #ascontainer></div>
  <div class="apps">
    <!-- do this in the loop  : TO BE DONE-->
    <div class="app-placeholder" *ngFor="let app of userApps; let i=index" (click)="selectedApp(app)">
      <div class="app-block elevation">
        <div class="app-icon">
          <img src="{{app.IconLink}}" />
        </div>
        <!-- <div class="app-name">
          {{ app.name }}
        </div> -->
      </div>
      <!-- <div class="default-sel">
        Make this default app
      </div> -->
    </div>
  </div>
</div>