import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentInfobarComponent } from './components/content-infobar/content-infobar.component';
import { SideToolbarComponent } from './components/side-toolbar/side-toolbar.component';
import { HeaderToolbarComponent } from './components/header-toolbar/header-toolbar.component';
import { MaterialUIModule } from '../material-ui/material-ui.module';
// import { OneToolbarsModule } from '../one-toolbars/one-toolbars.module';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { OneAuthService, OneLoginModule, OneToolbarModule } from 'one-auth';

@NgModule({
  declarations: [
    HeaderToolbarComponent,
    SideToolbarComponent,
    ContentInfobarComponent
  ],
  imports: [
    CommonModule,
    MaterialUIModule,
    OneLoginModule, 
    OneToolbarModule,
    TranslateModule,
    RouterModule
  ],
  providers:[
    OneAuthService
  ],
  exports: [
    HeaderToolbarComponent,
    SideToolbarComponent,
    ContentInfobarComponent
  ]
})
export class AppToolbarsModule { }
